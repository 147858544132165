import { metaDataConstants } from "../constants";

const initialState = {
    disciplines: [],
    buildings: [],
    floors: [],
};

export function metaData(state = initialState, action) {
    switch (action.type) {
        case metaDataConstants.DISCIPLINE: {
            return {
                ...state,
                disciplines: action.payload
            }
        }
        case metaDataConstants.BUILDING: {
            return {
                ...state,
                buildings: action.payload
            }
        }
        case metaDataConstants.FLOOR: {
            return {
                ...state,
                floors: action.payload
            }
        }

        default:
            return state;
    }
}