import { userConstants } from '../constants';

const initialState = {
    loggedIn: false,
    loggingIn: false,
    currentUser: {},
    withTwoFactorCode: false,
};

export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.USER_LOGIN: {
            return {
                ...state,
                loggedIn: action.payload,
                loggingIn: false
            }
        }
        case userConstants.USER_LOGOUT: {
            return {
                ...state,
                loggedIn: !action.payload,
                loggingIn: false,
                currentUser: {}
            }
        }
        case userConstants.USER_REFRESH: {
            return {
                ...state,
                loggedIn: action.payload,
                loggingIn: false
            }
        }
        case userConstants.USER_LOGGING: {
            return {
                ...state,
                loggingIn: action.payload
            }
        }
        case userConstants.USER_CURRENT: {
            return {
                ...state,
                currentUser:action.payload
            }
        }
        case userConstants.TWO_FACTOR_CODE: {
            return {
                ...state,
                withTwoFactorCode:action.payload
            }
        }
            
        default:
            return state;
    }
}