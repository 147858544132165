import { apiService } from "../../services";
import { metaDataConstants } from "../constants";
import { labelType } from "../../components/enums/labelType"

export const metaDataActions = {
    getDisciplines,
    getBuildings,
    getFloors,
}

function getDisciplines(ids) {
    return dispatch => {
        apiService.getArrayOData(`label?$filter=type in ('${labelType.MetaDataDiscipline}')`, ids).then(disciplines => {
            dispatch(setDisciplines(disciplines));
        }).catch(err => {
            console.log(err.message);
        });
    }

    function setDisciplines(disciplines) {
        return {
            type: metaDataConstants.DISCIPLINE,
            payload: disciplines
        };
    }
}

function getBuildings(ids) {
    return dispatch => {
        apiService.getArrayOData(`label?$filter=type in ('${labelType.MetaDataBuilding}')`, ids)
            .then(buildings => {
                dispatch(setBuildings(buildings));
            }).catch(err => {
                console.log(err.message);
            });
    }

    function setBuildings(buildings) {
        return {
            type: metaDataConstants.BUILDING,
            payload: buildings
        };
    }
}

function getFloors(ids) {
    return dispatch => {
        apiService.getArrayOData(`label?$filter=type in ('${labelType.MetaDataFloor}')`, ids)
            .then(floors => {
                dispatch(setFloors(floors));
            }).catch(err => {
                console.log(err.message);
            });
    }

    function setFloors(floors) {
        return {
            type: metaDataConstants.FLOOR,
            payload: floors
        };
    }
}