import { apiService } from '../../services';
import { commentConstants, detailConstants, issuesConstants } from '../constants';

export const detailActions = {
    selectedIssue,
    getComments,
    clearComments,
    sendComment,
    updateStatus,
}

function selectedIssue(issue) {
    return {
        type: detailConstants.DETAIL_SELECT,
        payload: issue
    }
}

function getComments(ids) {
    return dispatch => {
        apiService.getArrayData('comment', ids)
            .then(comments => {
                dispatch(registerComments(comments));
            }).catch(err => {
                console.log(err.message);
            });
    }

    function registerComments(comments) {
        return {
            type: commentConstants.COMMENT_GET,
            payload: comments
        }
    }
}

function clearComments() {
    return {
        type: commentConstants.COMMENT_CLAER,
    };
}

function sendComment(comment) {
    return dispatch => {
        apiService.postData('comment', comment).then(res => {
            dispatch(addComment(res.data));
        }).catch(err => {
            console.log(err.response);
        });
    }

    function addComment(data) {
        return {
            type: commentConstants.COMMENT_ADD,
            payload: data
        }
    }
}

function updateStatus(status) {
    return dispatch => {
        apiService.putData('issue', status).then(res => {
            dispatch(updateIssueLabel(res.data));
            dispatch(updateIssueStatus(res.data));
        }).catch(err => {
            console.log(err.response);
        });
    }

    function updateIssueStatus(data) {
        return {
            type: detailConstants.DETAIL_SELECT,
            payload: data[0]
        };
    }

    function updateIssueLabel(data) {
        return {
            type: issuesConstants.ISSUES_UPDATE,
            payload: data[0]
        }
    }
}