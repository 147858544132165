import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareDown, faCaretSquareUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import de from '../../assets/de.json';
import en from '../../assets/en.json';
import '../../css/viso_collapse.css';

library.add(faCaretSquareDown, faCaretSquareUp);

class VisoCollapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isCollapsed: false };
    }

    componentDidMount() {
        this.setState({ isCollapsed: this.props.defaultState });
    }

    getDesc() {
        let { count, keyString } = this.props;

        if (count) {
            const lang = window.isGerman ? de : en;
            const key = count === 1 ? keyString : `${keyString}s`;
            return `${count} ${lang[key]}`;
        }

        return '';
    }

    handleClick = () => {
        const isCollapsed = this.state.isCollapsed;
        this.setState({ isCollapsed: !isCollapsed });
    }

    render() {
        const { children, title } = this.props;
        const desc = this.getDesc();
        const isCollapsed = this.state.isCollapsed;

        return (
            <>
                <div className='collapse_header' onClick={this.handleClick}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ fontWeight: 'bold', margin: '0 20px 0 0' }}>{title}</div>
                        {
                            desc &&
                            <div>{desc}</div>
                        }
                    </div>
                    <FontAwesomeIcon icon={isCollapsed ? 'caret-square-down' : 'caret-square-up'} color={'#666666'} />
                </div>
                <div className={`collapse_content ${isCollapsed ? "collapsed" : "expanded"}`} aria-expanded={isCollapsed}>
                    {children}
                </div>
            </>
        )
    }
}

export default VisoCollapse;