import React, { useState } from 'react';
import '../../css/expandableTextBlock.scss';

const ExpandableTextBlock = ({ content }) => {
  const [isMore, setIsMore] = useState(true);

  return (
    <div className='expandableText'>
      <p className={`expandableText-content${isMore ? '_more' : ''}`}>
        {content}
      </p>
      <p className='expandableText-labelMore' onClick={() => setIsMore(!isMore)}>
        {
          isMore ?
            content?.length > 600 ? 'More' : '' : 'Less'
        }
      </p>
    </div>
  );
};

export default ExpandableTextBlock;