import React from "react";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import en from "../../assets/en.json";
import de from "../../assets/de.json";

const customStyles = {
  menuList: (base) => ({
    ...base,
    height: "100%",
    "::-webkit-scrollbar": {
      width: "6px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#D4D4D4",
      borderColor: "transparent",
      borderRadius: "20px",
    },
  }),
  option: (provided) => ({
    ...provided,
    color: "#66666",
    textAlign: "left",
  }),
  control: (provided) => ({
    ...provided,
    border: "1px solid #D2D2D2",
    borderRadius: "3px",
    height: "30px",
    minHeight: "30px",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon={props.selectProps.menuIsOpen ? faCaretUp : faCaretDown}
        />
      </components.DropdownIndicator>
    )
  );
};

const Option = (props) => {
  return (
    components.Option && (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        {props.data.label}
      </components.Option>
    )
  );
};

const ValueContainer = ({ children, ...props }) => {
  const lang = window.isGerman ? de : en;
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  const selectInput = React.Children.toArray(children).find(
    (input) => input.type.name === "Input" || input.type.name === "DummyInput"
  );

  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {`${nbValues} ${lang["selected"]}`}
      {selectInput}
    </components.ValueContainer>
  );
};

class VisoSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false, value: undefined, isMultiSelect: false };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ value: this.getDefaultOption() });
    if (this.props.isMultiSelect) {
      this.setState({ isMultiSelect: this.props.isMultiSelect });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ disabled: this.props.disabled });
    }

    if (prevProps.defaultValue !== this.props.defaultValue) {
      const option = this.getDefaultOption();
      this.setState({ value: option });
    }
  }

  getDefaultOption() {
    const options = this.props.options;
    if (this.props.isMultiSelect) {
      return options.filter((o) => this.props.defaultValue.includes(o.value));
    }
    return options.find((o) => o.value === this.props.defaultValue);
  }

  handleChange = (selectedOption) => {
    if (this.state.isMultiSelect) {
      const values = selectedOption.map((o) => o.value);
      this.props.onChange(values);
    } else {
      this.props.onChange(selectedOption?.value);
    }
    this.setState({ value: selectedOption });
  };

  render() {
    const { options, isClearable, isSearchable } = this.props;
    const searchable = isSearchable ? true : false;
    const { disabled, value, isMultiSelect } = this.state;

    return (
      <div>
        {
          <Select
            options={options}
            isMulti={isMultiSelect}
            value={value}
            components={
              isMultiSelect
                ? { DropdownIndicator, Option, ValueContainer }
                : { DropdownIndicator }
            }
            closeMenuOnSelect={!isMultiSelect}
            hideSelectedOptions={isMultiSelect ? false : null}
            isSearchable={isMultiSelect || searchable}
            isClearable={isMultiSelect || isClearable}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: "#F4F4F4",
                primary25: "#F4F4F4",
                primary50: "#F4F4F4",
              },
            })}
            isDisabled={disabled}
            onChange={this.handleChange}
          />
        }
      </div>
    );
  }
}

export default VisoSelect;
