import { combineReducers } from 'redux';
import { user } from './userReducer';
import { project } from './projectReducer';
import { issues } from './issueListReducer';
import { viewpoint } from './viewpointReducer';
import { filter } from './filterReducer';
import { detail } from './detailReducer';
import { comment } from './commentReducer';
import { metaData } from './metaDataReducer';
import { models } from './modelReducer';
import { editReport } from './editReducer';
import { issueDescriptor } from './issueDescriptorReducer';
import { email } from './emailReducer';

export default combineReducers({ user, project, issues, viewpoint, filter, detail, comment, metaData, models, editReport, issueDescriptor, email });