import React, { forwardRef, useEffect, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const customStyles = (isBad) => ({
  menuList: (base) => ({
    ...base,
    height: "100%",
    "::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#D4D4D4",
      borderColor: "transparent",
      borderRadius: "20px",
    },
  }),
  option: (provided) => ({
    ...provided,
    color: "#66666",
    textAlign: "left",
  }),
  control: (provided, state) => ({
    ...provided,
    border: isBad ? "1px solid red" : "1px solid #D2D2D2",
    borderRadius: "3px",
    height: "32px",
    minHeight: "30px",
  }),
});

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}
        />
      </components.DropdownIndicator>
    )
  );
};

const EmptySingleValue = () => null;

const EditableInput = ({ value: inputValue, isHidden, ...props }) => {
  const {
    selectProps: { value, getOptionLabel },
  } = props;

  const label = useMemo(() => {
    if (!value) {
      return "";
    }

    return getOptionLabel(value);
  }, [getOptionLabel, value]);

  const v = useMemo(() => {
    if (!inputValue) {
      return label;
    }

    return inputValue;
  }, [inputValue, label]);

  const hidden = useMemo(() => {
    if (v) {
      return false;
    }

    return isHidden;
  }, [isHidden, v]);

  return <components.Input isHidden={hidden} value={v} {...props} />;
};

const CreateNameSelect = forwardRef(
  ({ options, defaultValue, onChange, isBad }, ref) => {
    const [value, setValue] = useState(undefined);

    useEffect(() => {
      ref.current = value;
    });

    useEffect(() => {
      if (defaultValue) {
        setValue({ value: defaultValue, label: defaultValue });
      }
    }, [defaultValue]);

    const handleChange = (option) => {
      setValue(option);
      onChange(option);
    };

    return (
      <CreatableSelect
        components={{
          DropdownIndicator,
          Input: EditableInput,
          SingleValue: EmptySingleValue,
        }}
        styles={customStyles(isBad)}
        options={options}
        onChange={handleChange}
        value={value}
        placeholder=""
      />
    );
  }
);

export default CreateNameSelect;
