import React from "react";
import Moment from "moment";
import { issueLogEntryType } from "../enums";
import de from "../../assets/de.json";
import en from "../../assets/en.json";
import "../../css/issueHistory.scss";

function formatLocaleDate(date) {
  return window.isGerman
    ? Moment(date).locale("de").format("YYYY-MM-DD-HH:mm")
    : Moment(date).locale("en").format("DD/MM/YYYY-HH:mm");
}

const IssueLog = ({
  log,
  users,
  documents,
  disciplines,
  buildings,
  floors,
  workphases,
}) => {
  const lang = window.isGerman ? de : en;

  const toggleDetails = (event) => {
    event.target.parentElement.parentElement.classList.toggle("opened");
  };

  switch (log.entryType) {
    case issueLogEntryType.IssueCreated:
      return (
        <div className="issue-history--row">{lang["IssueWasCreated"]}</div>
      );
    case issueLogEntryType.StatusIdUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${lang["UpdatedStatus"]
              .replace("%1", log.oldValue)
              .replace("%2", log.newValue)}`,
          }}
        ></div>
      );
    case issueLogEntryType.TypeIdUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${lang["TypeChanged"]
              .replace("%1", log.oldValue)
              .replace("%2", log.newValue)}`,
          }}
        ></div>
      );
    case issueLogEntryType.DescriptionUpdated:
      return (
        <div className="issue-history--row">
          <div className="issue-history--row--title">
            <span
              dangerouslySetInnerHTML={{
                __html: lang["DescriptionChanged"],
              }}
            ></span>
            <a
              className="btn-toggle-issue-log btn-toggle-issue-log--show"
              onClick={toggleDetails}
            >
              {lang["More"]}
            </a>
            <a
              className="btn-toggle-issue-log btn-toggle-issue-log--hide"
              onClick={toggleDetails}
            >
              {lang["Less"]}
            </a>
          </div>
          <div className="issue-history--row--details">
            <div className="issue-history--row--details--row">
              <strong>{lang["Old"]}</strong>
              <div>{log.oldValue}</div>
            </div>
            <div className="issue-history--row--details--row">
              <strong>{lang["New"]}</strong>
              <div>{log.newValue}</div>
            </div>
          </div>
        </div>
      );
    case issueLogEntryType.TitleUpdated:
      return (
        <div className="issue-history--row">
          <div className="issue-history--row--title">
            <span
              dangerouslySetInnerHTML={{
                __html: lang["TitleChanged"],
              }}
            ></span>
            <a
              className="btn-toggle-issue-log btn-toggle-issue-log--show"
              onClick={toggleDetails}
            >
              {lang["More"]}
            </a>
            <a
              className="btn-toggle-issue-log btn-toggle-issue-log--hide"
              onClick={toggleDetails}
            >
              {lang["Less"]}
            </a>
          </div>
          <div className="issue-history--row--details">
            <div className="issue-history--row--details--row">
              <strong>{lang["Old"]}</strong>
              <div>{log.oldValue}</div>
            </div>
            <div className="issue-history--row--details--row">
              <strong>{lang["New"]}</strong>
              <div>{log.newValue}</div>
            </div>
          </div>
        </div>
      );
    case issueLogEntryType.PriorityIdUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${lang["PriorityChanged"]
              .replace("%1", log.oldValue)
              .replace("%2", log.newValue)}`,
          }}
        ></div>
      );
    case issueLogEntryType.DueDateUpdated:
    case issueLogEntryType.DueDateRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.newValue
                ? lang["RemovedData"].replace("%1", lang["DueDate"])
                : !log.oldValue
                ? lang["AddedDueDate"].replace(
                    "%1",
                    formatLocaleDate(new Date(log.newValue))
                  )
                : lang["UpdatedDueDate"]
                    .replace("%1", formatLocaleDate(new Date(log.oldValue)))
                    .replace("%2", formatLocaleDate(new Date(log.newValue)))
            }`,
          }}
        />
      );
    case issueLogEntryType.AssignedToUpdated:
    case issueLogEntryType.AssignedToRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.newValue
                ? lang["RemovedData"].replace("%1", lang["AssignedUser"])
                : !log.oldValue
                ? lang["AddedAssignedUsers"].replace(
                    "%1",
                    log.newValue?.map((u) => users[u]).join(", ")
                  )
                : lang["UpdatedAssignedUsers"]
                    .replace(
                      "%1",
                      log.oldValue?.map((u) => users[u]).join(", ")
                    )
                    .replace(
                      "%2",
                      log.newValue?.map((u) => users[u]).join(", ")
                    )
            }`,
          }}
        ></div>
      );
    case issueLogEntryType.LabelAdded:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["AddedData"].replace("%1", lang["Label"]),
          }}
        ></div>
      );
    case issueLogEntryType.LabelRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["RemovedData"].replace("%1", lang["Label"]),
          }}
        ></div>
      );
    case issueLogEntryType.StageAdded:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["AddedData"].replace("%1", lang["Stage"]),
          }}
        ></div>
      );
    case issueLogEntryType.StageRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["RemovedData"].replace("%1", lang["Stage"]),
          }}
        ></div>
      );
    case issueLogEntryType.StageUpdated:
      return "";
    case issueLogEntryType.DocumentFirstLinked:
    case issueLogEntryType.DocumentLinkVersionUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["UpdatedLinkDocument"]
              .replace(
                "%1",
                documents.find((d) => d.id === log.documentVersionId)?.name
              )
              .replace(
                "%2",
                documents.find((d) => d.id === log.documentVersionId)
                  ?.versionNumber
              ),
          }}
        ></div>
      );
    case issueLogEntryType.DocumentFirstLinkRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["RemovedData"].replace("%1", lang["Document"]),
          }}
        ></div>
      );
    case issueLogEntryType.ReviewerUpdated:
    case issueLogEntryType.ReviewerRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.oldValue
                ? lang["SetReviewer"].replace("%1", users[log.newValue])
                : !log.newValue
                ? lang["RemovedData"].replace("%1", lang["ReviewerFlt"])
                : lang["UpdatedReviewer"]
                    .replace("%1", users[log.oldValue])
                    .replace("%2", users[log.newValue])
            }`,
          }}
        ></div>
      );
    case issueLogEntryType.DisciplineMetaDataUpdated:
    case issueLogEntryType.CustomDisciplineMetaDataUpdated:
    case issueLogEntryType.DisciplineMetaDataRemoved:
    case issueLogEntryType.CustomDisciplineMetaDataRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.oldValue
                ? lang["AddedDisciplines"].replace(
                    "1%",
                    log.newValue?.map((d) => disciplines[d]).join(", ")
                  )
                : !log.newValue
                ? lang["RemovedData"].replace("%1", lang["Discipline"])
                : lang["UpdatedDisciplines"]
                    .replace(
                      "%1",
                      log.newValue?.map((d) => disciplines[d]).join(", ")
                    )
                    .replace(
                      "%2",
                      log.oldValue?.map((d) => disciplines[d]).join(", ")
                    )
            }`,
          }}
        ></div>
      );
    case issueLogEntryType.BuildingMetaDataUpdated:
    case issueLogEntryType.CustomBuildingMetaDataUpdated:
    case issueLogEntryType.BuildingMetaDataRemoved:
    case issueLogEntryType.CustomBuildingMetaDataRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.oldValue
                ? lang["AddedBuildings"].replace(
                    "%1",
                    log.newValue?.map((b) => buildings[b]).join(", ")
                  )
                : !log.newValue
                ? lang["RemovedData"].replace("%1", lang["Building"])
                : lang["UpdatedBuildings"]
                    .replace(
                      "%1",
                      log.newValue?.map((b) => buildings[b]).join(", ")
                    )
                    .replace(
                      "%2",
                      log.oldValue?.map((b) => buildings[b]).join(", ")
                    )
            }`,
          }}
        ></div>
      );
    case issueLogEntryType.FloorMetaDataUpdated:
    case issueLogEntryType.CustomFloorMetaDataUpdated:
    case issueLogEntryType.FloorMetaDataRemoved:
    case issueLogEntryType.CustomFloorMetaDataRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.oldValue
                ? lang["AddedFloors"].replace(
                    "%1",
                    log.newValue?.map((f) => floors[f]).join(", ")
                  )
                : !log.newValue
                ? lang["RemovedData"].replace("%1", lang["Floor"])
                : lang["UpdatedFloors"]
                    .replace(
                      "%1",
                      log.newValue?.map((f) => floors[f]).join(", ")
                    )
                    .replace(
                      "%2",
                      log.oldValue?.map((f) => floors[f]).join(", ")
                    )
            }`,
          }}
        ></div>
      );
    case issueLogEntryType.ModelsUpdated:
    case issueLogEntryType.ModelsRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["ChangedSome"],
          }}
        ></div>
      );
    case issueLogEntryType.LinkedComponentsUpdated:
    case issueLogEntryType.LinkedComponentsRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.oldValue
                ? lang["AddedElements"].replace("%1", log.newValue?.length)
                : !log.newValue
                ? lang["RemovedData"].replace("%1", lang["Element"])
                : lang["UpdatedElements"]
                    .replace("%1", log.newValue?.length)
                    .replace("%2", log.oldValue?.length)
            }`,
          }}
        ></div>
      );
    case issueLogEntryType.TagsUpdated:
    case issueLogEntryType.TagsRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.oldValue
                ? lang["AddedTags"].replace("%1", log.newValue?.length)
                : !log.newValue
                ? lang["RemovedData"].replace("%1", lang["Tag"])
                : lang["UpdatedTags"]
                    .replace("%1", log.newValue?.length)
                    .replace("%2", log.oldValue?.length)
            }`,
          }}
        ></div>
      );
    case issueLogEntryType.ViewpointAdded:
    case issueLogEntryType.ViewpointRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.oldValue
                ? lang["AddedViewpoints"].replace("%1", log.newValue?.length)
                : !log.newValue
                ? lang["RemovedData"].replace("%1", lang["Viewpoint"])
                : lang["UpdatedViewpoints"]
                    .replace("%1", log.newValue?.length)
                    .replace("%2", log.oldValue?.length)
            }`,
          }}
        ></div>
      );
    case issueLogEntryType.StartingDateUpdated:
    case issueLogEntryType.StartingDateRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.newValue
                ? lang["RemovedData"].replace("%1", lang["StartDate"])
                : !log.oldValue
                ? lang["AddedStartDate"].replace(
                    "%1",
                    formatLocaleDate(new Date(log.newValue))
                  )
                : lang["UpdatedStartDate"]
                    .replace("%1", formatLocaleDate(new Date(log.oldValue)))
                    .replace("%2", formatLocaleDate(new Date(log.newValue)))
            }`,
          }}
        />
      );
    case issueLogEntryType.WorkPhaseUpdated:
    case issueLogEntryType.WorkPhaseRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.oldValue
                ? lang["AddedWorkstage"].replace(
                    "%1",
                    workphases.find((w) => w.id === log.newValue)?.name
                  )
                : !log.newValue
                ? lang["RemovedData"].replace("%1", lang["Workstage"])
                : lang["UpdatedWorkstage"]
                    .replace(
                      "%1",
                      workphases.find((w) => w.id === log.newValue)?.name
                    )
                    .replace(
                      "%2",
                      workphases.find((w) => w.id === log.oldValue)?.name
                    )
            }`,
          }}
        />
      );
    case issueLogEntryType.VisibilityUpdated:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["UpdatedVisibility"]
              .replace("%1", log.oldValue)
              .replace("%2", log.newValue),
          }}
        />
      );
    case issueLogEntryType.AllowedRolesAdded:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: lang["AddedData"].replace("%1", lang["AllowdRoles"]),
          }}
        />
      );
    case issueLogEntryType.LinkedEmailUpdated:
    case issueLogEntryType.LinkedEmailRemoved:
      return (
        <div
          className="issue-history--row"
          dangerouslySetInnerHTML={{
            __html: `${
              !log.newValue.length
                ? lang["RemovedData"].replace("%1", lang["Mail"])
                : !log.oldValue.length
                ? lang["AddedEmail"].replace("%1", log.newValue)
                : lang["UpdatedEmail"]
                    .replace("%1", log.oldValue)
                    .replace("%2", log.newValue)
            }`,
          }}
        />
      );
    case issueLogEntryType.ParentUpdated:
    case issueLogEntryType.ParentRemoved:
    case issueLogEntryType.StatusUpdate:
    case issueLogEntryType.TypeUpdated:
    case issueLogEntryType.PriorityUpdated:
      return "";
    default:
      return "";
  }
};

const IssueHistory = ({
  log,
  users,
  documents,
  disciplines,
  buildings,
  floors,
  workphases,
}) => {
  return (
    <div className="issue-history">
      <div className="issue-history--title">
        <strong>{users[log.userId]}</strong>
        {log.creationDate ? (
          <span className="issue-history--creation-date">
            {formatLocaleDate(new Date(log.creationDate))}
          </span>
        ) : (
          <></>
        )}
      </div>
      <div>
        <IssueLog
          log={log}
          users={users}
          documents={documents}
          disciplines={disciplines}
          buildings={buildings}
          floors={floors}
          workphases={workphases}
        />
      </div>
    </div>
  );
};

export default IssueHistory;
