import { modelConstants } from "../constants";

const initialState = {
    models: []
}

export function models(state = initialState, action) {
    switch (action.type) {
        case modelConstants.MODEL_GETALL: {
            return {
                ...state,
                models: action.payload
            };
        }
        case modelConstants.MODEL_CLEAR: {
            return initialState;
        }
        default:
            return state;
    }
}