//import { apiService } from "../../services";
import { filterConstants } from "../constants";

export const filterActions = {
  applyFilter,
  resetFilter,
};

function applyFilter(data) {
  return (dispatch) => {
    /*
        const endpoint = 'queryfilter';
        if (!!data.id) {
            apiService.patchData(endpoint, data).then(res => {
                if (res.data) {
                    const query = data.uiFilterSettings.value.issueFilter;
                    query.id = data.id;
                    dispatch(registerFilter(query));
                }
            }).catch(err => {
                console.log(err);
            });
        } else {
            apiService.postData(endpoint, data).then(res => {
                const query = res.data.uiFilterSettings.issueFilter;
                query.id = res.data.id;
                dispatch(registerFilter(query));
            }).catch(err => {
                console.log("Can't create a query filter.", err.message);
            });
        }
        */

    dispatch(registerFilter(data));
  };

  function registerFilter(data) {
    return {
      type: filterConstants.FILTER_APPLY,
      payload: data,
    };
  }
}

/*
function resetFilter(id) {
  return (dispatch) => {
    apiService
      .deleteData("queryfilter", [id])
      .then((res) => {
        if (res.data) dispatch(clearFilter());
      })
      .catch((err) => {
        console.log("Can't delete query filter.", err.message);
      });
  };

  function clearFilter() {
    return {
      type: filterConstants.FILTER_RESET,
    };
  }
}
*/

function resetFilter() {
  return {
    type: filterConstants.FILTER_RESET,
  };
}
