import { apiService } from "../../services";
import { viewpointConstants } from "../constants";

export const viewpointActions = {
    getViewpoint: getViewpointsAsync,
    getImage: getImageAsync,
    postImage: postImageAsync,
    clearSnapshot: clearSnapshot,
};

function getViewpointsAsync(ids) {
    return dispatch => {
        apiService.getArrayData('viewpoint', ids)
            .then(viewpoints => {
                dispatch(insertViewpoints(viewpoints));
            }).catch(err => {
                console.log('Fail getting viewpoint', err.message);
            });
    }

    function insertViewpoints(viewpoints) {
        return {
            type: viewpointConstants.VP_GETALL,
            payload: viewpoints
        }
    }
}

function getImageAsync(id) {
    return dispatch => {
        apiService.getResourceStream(id)
            .then(res => {
                dispatch(insertImage(id, res.data));
            }).catch(err => {
                console.log('Fail getting image', err.message);
            });
    }


    function insertImage(id, data) {
        let image = Buffer.from(data).toString('base64');
        image = 'data:image/png;base64,' + image;

        return {
            type: viewpointConstants.VP_ADD_RESOURCE,
            payload: { id: id, img: image }
        }
    }
}

function postImageAsync(id) {
    return {
        type: viewpointConstants.VP_SET_SNAPSHOTID,
        payload: id
    }
}

function clearSnapshot() {
    return {
        type: viewpointConstants.VP_CLEAR_SNAPSHOT
    }
}