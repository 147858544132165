import { apiService } from "../../services";
import { issueDescriptorConstants } from "../constants";
import { labelType } from "../../components/enums/labelType"

export const issueDescriptorActions = {
  getIssueDescriptors,
};

function getIssueDescriptors(projectId) {
  return (dispatch) => {
    apiService
      .getDataByEndpoint(`label?$filter=type in ('${labelType.IssueStatus}')`)
      .then((res) => {
        dispatch(setStatuses(res.data));
      })
      .catch((err) => {
        console.log(err.message);
      });

    apiService
      .getDataByEndpoint(`label?$filter=type in ('${labelType.IssuePriority}')`)
      .then((res) => {
        dispatch(setPriorities(res.data));
      })
      .catch((err) => {
        console.log(err.message);
      });

    apiService
      .getDataByEndpoint(`label?$filter=type in ('${labelType.IssueType}')`)
      .then((res) => {
        dispatch(setTypes(res.data));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function setPriorities(priorities) {
    return {
      type: issueDescriptorConstants.PRIORITY,
      payload: priorities,
    };
  }

  function setStatuses(statuses) {
    return {
      type: issueDescriptorConstants.STATUS,
      payload: statuses,
    };
  }

  function setTypes(types) {
    return {
      type: issueDescriptorConstants.TYPE,
      payload: types,
    };
  }
}
