import React, {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { publishPanelKind } from "../enums";
import { getGeneralSvg } from "../controls/svg";
import { connect, useSelector } from "react-redux";
import { apiService } from "../../services";
import { TreeSelect } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import CreateNameSelect from "../controls/CreateNameSelect";
import { converters } from "../helper";
import VisoSelect from "../controls/viso_select";
import en from "../../assets/en.json";
import de from "../../assets/de.json";
import "../../css/exportPublisher.css";

const PublishSetItem = forwardRef(({ setItem, folderTree }, ref) => {
  const metaData = useSelector((state) => state.metaData);

  const nameRef = useRef();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isImportModel, setIsImportModel] = useState(false);
  const [lang, setLang] = useState(en);
  const [folder, setFolder] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [fileName, setFileName] = useState();
  const [disciplines, setDisciplines] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [discipline, setDiscipline] = useState();
  const [building, setBuilding] = useState();
  const [floor, setFloor] = useState();
  const [isBad, setIsBad] = useState(false);
  const [isIFC, setIsIFC] = useState(false);

  // https://stackoverflow.com/questions/55456604/how-to-call-a-child-method-on-parent-events-with-react
  useImperativeHandle(ref, () => ({
    uploadCheck: () => {
      const isBad = fileName === undefined || fileName.trim() === "";
      setIsBad(isBad);

      return !isBad;
    },
    hide: () => {
      setIsExpanded(false);
    },
    show: () => {
      setIsExpanded(true);
    },
  }));

  useEffect(() => {
    const lang = window.isGerman ? de : en;
    setLang(lang);
  }, []);

  useEffect(() => {
    setFolder(setItem.folder);
    setIsImportModel(setItem.isImport ? true : false);
    if (setItem.docName) {
      setFileName(setItem.docName);
    }
    setDiscipline(setItem.discipline);
    setBuilding(setItem.building);
    setFloor(setItem.floor);
    setIsIFC(setItem.ext.toUpperCase().includes("IFC"));
  }, [setItem]);

  useEffect(() => {
    if (!folder) {
      if (folderTree.length) {
        setFolder(folderTree.find((f) => f.title === "Shared")?.value);
      }
    } else {
      getFileNamesInFolder(folder);
    }
    // eslint-disable-next-line
  }, [folderTree, folder]);

  useEffect(() => {
    setItem.folder = folder;
    // eslint-disable-next-line
  }, [folder]);

  useEffect(() => {
    if (metaData) {
      const disciplines = converters.getOptionsFromMetaData(
        metaData.disciplines
      );
      const buildings = converters.getOptionsFromMetaData(metaData.buildings);
      const floors = converters.getOptionsFromMetaData(metaData.floors);

      setDisciplines(disciplines);
      setBuildings(buildings);
      setFloors(floors);

      if (disciplines.length) setDiscipline(disciplines[0].value);
      if (buildings.length) setBuilding(buildings[0].value);
      if (floors.length) setFloor(floors[0].value);
    }
  }, [metaData]);

  useEffect(() => {
    setItem.discipline = discipline;
    setItem.building = building;
    setItem.floor = floor;
    // eslint-disable-next-line
  }, [discipline, building, floor]);

  useEffect(() => {
    setItem.docName = fileName;
    // eslint-disable-next-line
  }, [fileName]);

  const getFileNamesInFolder = (folderId) => {
    const endpoint = `documentVersion?filter=folderId eq '${folderId}'`;
    apiService
      .getDataByEndpoint(endpoint)
      .then((res) => {
        let names = res.data.map((d) =>
          d.name.split(".").slice(0, -1).join(".")
        );
        names.push(setItem.title);
        names = [...new Set(names)];
        const fileNames = names.map((n) => {
          return { value: n, label: n };
        });

        setFileNames(fileNames);
        setFileName(setItem.title);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFolderChange = (folder) => {
    setFolder(folder);
  };

  const onNameChange = (e) => {
    setFileName(e.value);
  };

  const onSetImportModel = () => {
    const isImport = !isImportModel;
    setItem.isImport = isImport;
    setIsImportModel(isImport);
  };

  return (
    <div>
      <div
        className="horizental_align"
        style={{ justifyContent: "space-between" }}
      >
        <div
          className="horizental_align"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div>
            {isExpanded ? getGeneralSvg("expand") : getGeneralSvg("fold")}
          </div>
          <div style={{ margin: "0 8px" }}>{getGeneralSvg("leaf")}</div>
          <label
            style={{
              fontWeight: "600",
              fontFamily: "Open Sans",
              fontSize: "10px",
              lineHeight: "14px",
              color: "#666666",
            }}
          >
            {setItem.title}
          </label>
        </div>
        {isBad && (
          <label
            style={{
              color: "red",
              fontWeight: "400",
              fontFamily: "Open Sans",
              fontSize: "9px",
              lineHeight: "12px",
            }}
          >
            {lang["ExportMissing"]}
          </label>
        )}
      </div>
      {isExpanded && (
        <div style={{ margin: "0 0 0 15px" }}>
          <div className="select_container">
            <label
              style={{
                fontWeight: "400",
                fontFamily: "Open Sans",
                fontSize: "10px",
                lineHeight: "14px",
                color: "#666666",
              }}
            >
              {lang["ExportFolder"]}
            </label>
            {folderTree && (
              <TreeSelect
                suffixIcon={<CaretDownFilled />}
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                value={folder}
                treeData={folderTree}
                onChange={onFolderChange}
              />
            )}
          </div>
          <div className="select_container">
            <label
              style={{
                fontWeight: "400",
                fontFamily: "Open Sans",
                fontSize: "10px",
                lineHeight: "14px",
                color: "#666666",
              }}
            >
              {lang["FileName"]}
            </label>
            <div>
              <CreateNameSelect
                options={fileNames}
                defaultValue={fileName}
                onChange={onNameChange}
                isBad={isBad}
                ref={nameRef}
              />
            </div>
          </div>
          {isIFC && (
            <>
              <div className="horizental_align">
                <input
                  id="import_model"
                  type={"checkbox"}
                  checked={isImportModel}
                  onChange={onSetImportModel}
                />
                <div
                  htmlFor="import_model"
                  onClick={onSetImportModel}
                  style={{
                    margin: "0 5px",
                    fontFamily: "Open Sans",
                    fontSize: "10px",
                    lineHeight: "14px",
                    fontWeight: "400",
                    color: "#acacac",
                  }}
                >
                  {lang["ImportModelAutomatically"]}
                </div>
              </div>
              {isImportModel && (
                <div>
                  <label
                    style={{
                      fontWeight: "400",
                      fontFamily: "Open Sans",
                      fontSize: "10px",
                      lineHeight: "14px",
                      color: "#666666",
                    }}
                  >
                    {lang["SetupModel"]}
                  </label>
                  <div className="select_container">
                    <label
                      style={{
                        fontWeight: "400",
                        fontFamily: "Open Sans",
                        fontSize: "10px",
                        lineHeight: "14px",
                        color: "#666666",
                      }}
                    >
                      {lang["Discipline"]}
                    </label>
                    <VisoSelect
                      options={disciplines}
                      defaultValue={discipline}
                      onChange={(d) => setDiscipline(d)}
                    />
                  </div>
                  <div className="select_container">
                    <label
                      style={{
                        fontWeight: "400",
                        fontFamily: "Open Sans",
                        fontSize: "10px",
                        lineHeight: "14px",
                        color: "#666666",
                      }}
                    >
                      {lang["Building"]}
                    </label>
                    <VisoSelect
                      options={buildings}
                      defaultValue={building}
                      onChange={(b) => setBuilding(b)}
                    />
                  </div>
                  <div className="select_container">
                    <label
                      style={{
                        fontWeight: "400",
                        fontFamily: "Open Sans",
                        fontSize: "10px",
                        lineHeight: "14px",
                        color: "#666666",
                      }}
                    >
                      {lang["Floor"]}
                    </label>
                    <VisoSelect
                      options={floors}
                      defaultValue={floor}
                      onChange={(f) => setFloor(f)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
});

class PublishMainPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folderTree: [],
      publishItems: props.publishItems,
      isExpanded: true,
      publishSet: props.publishSet,
    };

    this.childrenRefs = props.publishItems.map(() => {
      return createRef();
    });

    window.publishMainPanel = this;
  }

  componentDidMount() {
    apiService
      .getFolderData()
      .then((res) => {
        if (res.data) {
          this.getTreeData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    window.getPublishItems(this.props.project.id);
  }

  componentWillUnmount() {
    window.setPublishHistory({
      projectId: this.props.project.id,
      publishSet: JSON.stringify(this.state.publishSet),
      publishItems: JSON.stringify(this.state.publishItems),
    });
  }

  setPublishItems(sItems) {
    try {
      const data = JSON.parse(sItems);
      const { publishItems, publishSet } = data;
      const jsPublishItems = JSON.parse(publishItems);
      const jsPublishSet = JSON.parse(publishSet);
      this.setState({ publishItems: jsPublishItems, publishSet: jsPublishSet });
      this.props.onSetPublishData(jsPublishItems, jsPublishSet);
    } catch (err) {
      console.log(err);
    }
  }

  getTreeData(folders) {
    const folderTree = folders.map((f) => {
      return this.getFolderTree(f);
    });

    this.setState({ folderTree });
  }

  getFolderTree(folder) {
    let data = {};
    data.value = folder.id;
    data.title = folder.name;

    if (folder.childFolders) {
      data.children = folder.childFolders.map((sub) => {
        return this.getFolderTree(sub);
      });
    }

    return data;
  }

  onClick = () => {
    this.props.onSetPanel(publishPanelKind.setFile);
  };

  onUploadCheck = () => {
    if (!this.state.publishItems.length) return;

    let checkResult = true;
    this.childrenRefs.forEach((childRef) => {
      const rs = childRef.current?.uploadCheck();
      checkResult = checkResult && rs;
    });

    if (checkResult) {
      this.props.onUploadCheck(this.state.publishItems, this.state.publishSet);
    }
  };

  onfoldPublishSets = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  handleHideAll = () => {
    this.childrenRefs.forEach((childRef) => {
      childRef.current?.hide();
    });
  };

  handleUnfold = () => {
    this.childrenRefs.forEach((childRef) => {
      childRef.current?.show();
    });
    //this.setState({ isExpanded: false });
  };

  renderItems() {
    return this.state.publishItems.map((item, index) => {
      return (
        <PublishSetItem
          setItem={item}
          folderTree={this.state.folderTree}
          isHidden={this.state.isAllHidden}
          ref={this.childrenRefs[index]}
        />
      );
    });
  }

  render() {
    const lang = window.isGerman ? de : en;
    const { publishItems, isExpanded, publishSet } = this.state;

    return (
      <div className="exportPublish">
        <div>
          <button
            className="visoplan_2ndButton"
            style={{ width: "100%" }}
            onClick={this.onClick}
          >
            {publishItems.length ? lang["EditFiles"] : lang["AddFiles"]}
          </button>
          {publishItems.length ? (
            <>
              <div
                className="horizental_align"
                style={{ margin: "20px 0 0 0" }}
              >
                <label
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: "13px",
                    fontWeight: "700",
                    lineHeight: "18px",
                    color: "#666666",
                  }}
                >
                  Publisher Set:
                </label>
                <div
                  style={{
                    margin: "0 8px",
                    fontFamily: "Open Sans",
                    fontSize: "11px",
                    fontWeight: "600",
                    lineHeight: "15px",
                    color: "#666666",
                  }}
                >
                  {publishSet?.title}
                </div>
              </div>
              <div
                className="horizental_align rtl"
                style={{ marginBottom: "5px" }}
              >
                <button
                  className="transparent_button"
                  style={{
                    textDecoration: "underline",
                    fontFamily: "Open Sans",
                    fontSize: "9px",
                    fontWeight: "400",
                    lineHeight: "12px",
                    color: "#acacac",
                  }}
                  onClick={this.handleUnfold}
                >
                  Unfold all
                </button>
                <button
                  className="transparent_button"
                  style={{
                    textDecoration: "underline",
                    fontFamily: "Open Sans",
                    fontSize: "9px",
                    fontWeight: "400",
                    lineHeight: "12px",
                    color: "#acacac",
                  }}
                  onClick={this.handleHideAll}
                >
                  Hide all
                </button>
              </div>
              <hr className="solid" />
            </>
          ) : (
            <div className="no-publish-notion">
              <label
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "11px",
                  lineHeight: "15px",
                }}
              >
                {"Select a publisher set and add files to the selection"}
              </label>
            </div>
          )}
        </div>
        <div
          className="publish_middle scrollbar"
          style={{
            maxHeight: publishItems.length
              ? "calc(100% - 100px)"
              : "calc(100% - 140px)",
          }}
        >
          <div
            className={publishItems.length ? "publish_set_folder" : "hide"}
            onClick={this.onfoldPublishSets}
          >
            <div className="horizental_align">
              <div>
                {isExpanded ? getGeneralSvg("expand") : getGeneralSvg("fold")}
              </div>
              <div style={{ margin: "0 8px" }}>{getGeneralSvg("folder")}</div>
              <label
                style={{
                  fontWeight: "700",
                  fontFamily: "Open Sans",
                  fontSize: "10px",
                  lineHeight: "14px",
                  color: "#666666",
                }}
              >
                {publishSet?.title}
              </label>
            </div>
            <div
              style={{
                fontFamily: "Open Sans",
                fontSize: "10px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#acacac",
              }}
            >{`${publishItems.length} Files`}</div>
          </div>
          <div
            style={{
              display: `${isExpanded ? "block" : "none"}`,
              marginLeft: "10px",
            }}
          >
            {this.renderItems()}
          </div>
        </div>
        <button
          className="visoplan_2ndButton"
          style={{ width: "100%" }}
          onClick={this.onUploadCheck}
        >
          {lang["UploadCheck"]}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
  };
};

export default connect(mapStateToProps, null)(PublishMainPanel);
