import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { viewpointActions } from '../../redux/actions';
import '../../css/image.css';
import img from '../../assets/sample.png';

library.add(faTimes, faTimesCircle);

class Image extends Component {
    constructor(props) {
        super(props);
        this.state = { selectable: false, deletable: false, isHover: false };
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.hanldeMouseLeave = this.hanldeMouseLeave.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        const id = this.props.id;
        if (id && id !== "" && parseInt(id) !== 0 && id.indexOf("data:image/png;") === -1) {
            this.props.getImage(id);
        }

        if (this.props.selectable) {
            this.setState({ selectable: this.props.selectable });
        }

        if (this.props.deletable) {
            this.setState({ deletable: this.props.deletable });
        }
    }

    handleClick(e) {
        e.preventDefault();
        if (this.state.selectable) {
            this.props.selectViewpoint(this.props.vId);
        }
    }

    handleMouseEnter(e) {
        e.preventDefault();
        if (this.state.deletable) {
            this.setState({ isHover: true });
        }
    }

    hanldeMouseLeave(e) {
        e.preventDefault();
        if (this.state.deletable) {
            this.setState({ isHover: false });
        }
    }

    handleDelete(e) {
        e.preventDefault();
        if (this.state.deletable) {
            this.props.deleteViewpoint(this.props.id);
        }
    }

    render() {
        const { resources, id, isList } = this.props;
        const { selectable, deletable, isHover } = this.state;

        let image = resources.find(r => r.id === id);
        if (!image) {
            if (id && id !== "" && parseInt(id) !== 0) {
                image = id;
            } else {
                image = img;
            }
        } else {
            image = image.img;
        }

        return (
            <>
                {
                    isList ?
                        <img style={{ display:'block', marginLeft:'auto', marginRight:'auto', width: '100%', maxHeight: '150px', maxWidth:'300px' }} src={image} alt='' />
                        :
                        <div className='viso_image_container'>
                            <img className={`viso_image ${selectable ? 'selectable' : ''}`} src={image} alt='' onClick={this.handleClick} />
                            {deletable &&
                                <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.hanldeMouseLeave}>
                                    <FontAwesomeIcon icon={isHover ? 'times-circle' : 'times'} onClick={this.handleDelete} style={{ color: '#666666' }} />
                                </div>
                            }
                        </div>
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        resources: state.viewpoint.resources
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getImage: (id) => { dispatch(viewpointActions.getImage(id)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);