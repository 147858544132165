import API from "./api";
import { serviceHelper } from "./helper";

const CHUNK_SIZE = 140;

export const apiService = {
  getCurrentUser: getCurrentUserAsync,
  getArrayData: getArrayDataAsync,
  getArrayOData: getArrayODataAsync,
  getData: getDataAsync,
  getResourceStream: getResourceStreamAsync,
  postData: postDataAsync,
  putData: putDataAsync,
  postImage: postImageAsync,
  deleteData: deleteDataAsync,
  patchData: patchDataAsync,
  syncData: syncDataAsync,
  getIfcStream: getIfcStreamAsync,
  downloadDocumentVersion: downloadDocumentVersionAsync,
  uploadIfcStream: uploadIfcStreamAsync,
  getFolderData: getFolderDataAsync,
  getDataByEndpoint: getDataByEndpointAsync,
  patchIfcDocument: patchIfcDocumentAsync,
  checkUploadDocument: checkUploadDocumentAsync,
  getProjectToken: getProjectTokenAsync,
  getProjects: getProjectsAsync,
  deleteDataByEndpoint: deleteDataByEndpointAsync,
};

async function getCurrentUserAsync() {
  return await API.get(
    `${serviceHelper.getBaseURL()}user/current`,
    serviceHelper.authHeader()
  );
}

async function getProjectsAsync(ids) {
  try {
    const subIds = splitIds(ids);
    let promises = subIds.map((ids) => {
      return API.get(
        serviceHelper.getEndpointOData("project", ids),
        serviceHelper.authHeader()
      );
    });
    if (!ids.length) {
      promises = [API.get(
        serviceHelper.getEndpointOData("project", []),
        serviceHelper.authHeader()
      )];
    }

    const responses = await Promise.all(promises);
    let data = [];
    responses.forEach((res) => {
      data = [...data, ...res.data];
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function getArrayDataAsync(endpoint, ids) {
  try {
    const subIds = splitIds(ids);
    const promises = subIds.map((ids) => {
      return API.get(
        serviceHelper.getEndpoint(endpoint, ids),
        serviceHelper.projectTokenHeader()
      );
    });

    const responses = await Promise.all(promises);
    let data = [];
    responses.forEach((res) => {
      data = [...data, ...res.data];
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function getArrayODataAsync(endpoint, ids) {
  try {
    let promises;
    if (ids && ids.length) {
      const subIds = splitIds(ids);
      promises = subIds.map((ids) => {
        return API.get(
          serviceHelper.getEndpointOData(endpoint, ids),
          serviceHelper.projectTokenHeader()
        );
      });
    } else {
      promises = [API.get(
        serviceHelper.getEndpointOData(endpoint, []),
        serviceHelper.projectTokenHeader()
      )];
    }

    const responses = await Promise.all(promises);
    let data = [];
    responses.forEach((res) => {
      data = [...data, ...res.data];
    });
    return data;
  } catch (err) {
    throw err;
  }
}

async function getDataAsync(endpoint) {
  return await API.get(
    serviceHelper.getEndpointOData(endpoint, []),
    serviceHelper.projectTokenHeader()
  );
}

function splitIds(ids) {
  return ids.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / CHUNK_SIZE);
    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }

    result[chunkIndex].push(item);
    return result;
  }, []);
}

async function getResourceStreamAsync(id) {
  return await API.get(
    `${serviceHelper.getBaseURL()}resource/${id}`,
    serviceHelper.resourceHeader()
  );
}

async function getIfcStreamAsync(id) {
  return await API.get(
    `${serviceHelper.getBaseURL()}resource/${id}`,
    serviceHelper.projectTokenHeader()
  );
}

async function postDataAsync(endpoint, data) {
  return await API.post(
    `${serviceHelper.getBaseURL()}${endpoint}`,
    data,
    serviceHelper.projectTokenHeader()
  );
}

async function putDataAsync(endpoint, data) {
  return await API.put(
    `${serviceHelper.getBaseURL()}${endpoint}`,
    data,
    serviceHelper.projectTokenHeader()
  );
}

async function postImageAsync(endpoint, data) {
  return await API.post(
    `${serviceHelper.getBaseURL()}resource/${endpoint}`,
    data,
    serviceHelper.uploadHeader()
  );
}

async function deleteDataAsync(endpoint, ids) {
  return await API.delete(
    `${serviceHelper.getBaseURL()}${endpoint}`,
    serviceHelper.deleteConfig(ids)
  );
}

async function patchDataAsync(endpoint, data) {
  return await API.patch(
    `${serviceHelper.getBaseURL()}${endpoint}`,
    JSON.stringify(data),
    {
      headers: {
        "X-Project-Authorization": `bearer ${
          serviceHelper.getProjectToken().bearer
        }`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
}

async function patchIfcDocumentAsync(versionId) {
  return await API.patch(
    `${serviceHelper.getBaseURL()}documentVersion/import/${versionId}`,
    undefined,
    serviceHelper.projectTokenHeader()
  );
}

async function syncDataAsync(id) {
  return await API.get(
    `${serviceHelper.getBaseURL()}sync/project/${id}`,
    serviceHelper.syncHeader()
  );
}

async function downloadDocumentVersionAsync(data) {
  return await API.post(
    `${serviceHelper.getBaseURL()}documentVersion/download`,
    data,
    serviceHelper.resourceHeader()
  );
}

async function uploadIfcStreamAsync(endpoint, data) {
  return await API.post(
    `${serviceHelper.getBaseURL()}upload/${endpoint}`,
    data,
    serviceHelper.uploadHeader()
  );
}

async function getFolderDataAsync() {
  return await API.get(
    `${serviceHelper.getBaseURL()}folder?includeDescendants=true&$filter=isTopLevel eq true`,
    serviceHelper.projectTokenHeader()
  );
}

async function getDataByEndpointAsync(endpoint) {
  return await API.get(
    `${serviceHelper.getBaseURL()}${endpoint}`,
    serviceHelper.projectTokenHeader()
  );
}

async function checkUploadDocumentAsync(
  projId,
  folderId,
  docStatusId,
  fileName
) {
  const blob = new Blob(["test", { type: "text/plain" }]);
  const formData = new FormData();
  formData.append("file", blob, fileName);
  return await API.post(
    `${serviceHelper.getBaseURL()}document/check?folderId=${folderId}&docStatusId=${docStatusId}`,
    formData,
    serviceHelper.uploadHeader()
  );
}

async function getProjectTokenAsync(projectId) {
  return await API.get(
    `${serviceHelper.getBaseURL()}auth-project/open/${projectId}`,
    serviceHelper.authHeader()
  );
}

async function deleteDataByEndpointAsync(endpoint) {
  return await API.delete(
    `${serviceHelper.getBaseURL()}${endpoint}`,
    serviceHelper.projectTokenHeader()
  );
}
