import { detailConstants } from '../constants';

const initialState = {
    selectedIssue: undefined
}

export function detail(state = initialState, action) {
    switch (action.type) {
        case detailConstants.DETAIL_SELECT: {
            return {
                ...state,
                selectedIssue: action.payload
            };
        }
        case detailConstants.DETAIL_CLEAR: {
            return initialState;
        }

        default:
            return state;
    }
}