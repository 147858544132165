import React from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { userActions } from "../redux/actions";
import { version } from "./helper";
import en from "../assets/en.json";
import de from "../assets/de.json";
import img from "../assets/visoplan-white.png";
import "../css/login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      remember: false,
      flag: 0,
      isGerman: false,
      authenticator_code: "",
    };

    this.updateAvailable = false;
    this.latestVersion = undefined;
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      this.getApplicationInfo();
    }, 100);
  }

  getApplicationInfo() {
    window.GetApplicationInfo();

    if (window.isGerman !== undefined) {
      this.setState({ isGerman: window.isGerman });
      clearInterval(this.timerId);
      this.getUpdatedVersionInfo();
    }
  }

  getUpdatedVersionInfo() {
    const axios = require("axios");
    const config = {
      method: "get",
      url: version.getUpdatedVersionUrl(),
      headers: {},
    };

    axios(config)
      .then((res) => {
        this.latestVersion = res.data;
        this.updateAvailable =
          versionCompare(version.getVersion(), this.latestVersion) < 0;
        if (this.updateAvailable) {
          this.showNotification();
        }

        this.props.refresh(this.props.history);
      })
      .catch((err) => {
        console.log(err);
      });

    function versionCompare(v1, v2) {
      let v1Parts = v1.split(".").map(Number);
      let v2Parts = v2.split(".").map(Number);

      if (v1Parts.length !== v2Parts.length) {
        return -1;
      }

      for (let i = 0; i < v1Parts.length; i++) {
        if (v1Parts[i] === v2Parts[i]) {
          continue;
        } else if (v1Parts[i] > v2Parts[i]) {
          return 1;
        } else {
          return -1;
        }
      }

      return 0;
    }
  }

  showNotification() {
    const lang = window.isGerman ? de : en;
    const content = lang["UpdateNotice"].replace("VERSION", this.latestVersion);
    window.AlertAPI(content);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const withTwoFactorCode = this.props.withTwoFactorCode;

    this.setState({ flag: 0 });
    const { email, password, remember, authenticator_code } = this.state;
    if(withTwoFactorCode) {
      this.props.twoFactorLogin(email, password, remember, authenticator_code, this.props.history);
    }else {
      this.props.login(email, password, remember, this.props.history);
    }
    
    this.setState({ flag: 1 });
  };

  handleMailChanged = (e) => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChanged = (e) => {
    this.setState({ password: e.target.value });
  };

  handleRememberChanged = (e) => {
    this.setState({ remember: e.target.checked });
  };

  handleAuthenticatorCodeChanged = (e) => {
    this.setState({ authenticator_code: e.target.value });
  };

  render() {
    const lang = this.state.isGerman ? de : en;
    const loggingIn = this.props.loggingIn;
    const visible = !loggingIn && this.state.flag === 1;
    const withTwoFactorCode = this.props.withTwoFactorCode;

    return (
      <div className="login_center">
        <form onSubmit={this.handleSubmit}>
          <div style={{ textAlign: "center" }}>
            <img src={img} alt="" />
          </div>
          <div className="login_row">
            <div style={{paddingBottom: withTwoFactorCode ? '15px' : '40px'}}  className="login_column">
              <label htmlFor="email">{lang["Email"]}</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder={lang["PromptEmail"]}
                className="visoplan_input"
                onChange={this.handleMailChanged}
              />
              {visible && (
                <span
                  style={{
                    color: "red",
                    display: "inline-block",
                    width: "200px",
                  }}
                >
                  {withTwoFactorCode ? lang["IncorrectInputTwoFactor"] : lang["IncorrectInput"] }
                </span>
              )}
            </div>
            <div style={{paddingBottom: withTwoFactorCode ? '15px' : '40px'}} className="login_column">
              <label htmlFor="password">{lang["Password"]}</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder={lang["PromptPassword"]}
                className="visoplan_input"
                onChange={this.handlePasswordChanged}
              />
            </div>
          </div>
          <div className="login_loader">
            {loggingIn && (
              <Loader type="TailSpin" color="#4B6FFC" height={20} width={20} />
            )}
          </div>
          {withTwoFactorCode && <div className="login_row">
            <div className="login_full_column">
              <label htmlFor="authenticator_code">{lang["AuthenticatorCode"]}</label>
              <input
                type="authenticator_code"
                id="authenticator_code"
                name="authenticator_code"
                placeholder={lang["PromptAuthenticatorCode"]}
                className="visoplan_input"
                onChange={this.handleAuthenticatorCodeChanged}
              />
            </div>
          </div>}
          <div className="login_button">
            <input
              type="checkbox"
              id="remember"
              name="remember"
              value="remember"
              onChange={this.handleRememberChanged}
            />
            <label htmlFor="remember" style={{ paddingRight: "10px" }}>
              {lang["RememberMe"]}
            </label>
            <button type="submit" className="visoplan_button">
              {lang["Start"]}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    loggingIn: state.user.loggingIn,
    withTwoFactorCode: state.user.withTwoFactorCode
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password, remember, history) => {
      dispatch(userActions.login(email, password, remember, history));
    },
    refresh: (history) => {
      dispatch(userActions.refresh(history));
    },
    twoFactorLogin: (email, password, remember, authenticator_code, history) => {
      dispatch(userActions.twoFactorLogin(email, password, remember, authenticator_code, history));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
