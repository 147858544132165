import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCaretDown, faCaretUp);

const customStyles = {
    menu: (base) => ({
        ...base,
        width: '100px',
        position: 'absolute',
        right: 0
    }),
    menuList: (base) => ({
        ...base,
        height: '100%',
        width: '100px',
        "::-webkit-scrollbar": {
            width: '6px'
        },
        "::-webkit-scrollbar-track": {
            background: 'transparent'
        },
        "::-webkit-scrollbar-thumb": {
            backgroundColor: '#D4D4D4',
            borderColor: 'transparent',
            borderRadius: '2px'
        }
    }),
    option: (provided) => ({
        ...provided,
        color: '#66666',
        textAlign: 'left',
    }),
    control: (provided) => ({
        ...provided,
        border: '1px solid #D2D2D2',
        borderRadius: '3px',
        height: '30px',
        minHeight: '30px'
    })
}

const DropdownIndicator = props => {
    return (components.DropdownIndicator && (
        <components.DropdownIndicator{...props}>
            <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? 'caret-up' : 'caret-down'} />
        </components.DropdownIndicator>
    ));
};

const Option = props => {
    return (components.Option && <components.Option{...props}>
        <label>{props.data.label}</label>
    </components.Option>)
}

const ValueContainer = ({ children, ...props }) => {
    const { getValue, hasValue } = props;

    if (!hasValue) {
        return (
            < components.ValueContainer{...props}>
                {children}
            </components.ValueContainer>
        );
    }

    let selectedOption = getValue().map(o => o.value);
    return (
        <components.ValueContainer{...props}>
            <div style={{ color: `${selectedOption[0]?.color}`, background: `${selectedOption[0]?.color}30`, padding: '0 5px', borderRadius: '3px' }}>{selectedOption[0]?.name}</div>
        </components.ValueContainer>
    );
}

class StatusSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = { disabled: false, value: {} };
    }

    componentDidMount() {
        this.setState({ value: this.getDefaultOption() });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.disabled !== this.props.disabled) {
            this.setState({ disabled: this.props.disabled });
        }

        if (prevProps.defaultValue !== this.props.defaultValue) {
            const option = this.getDefaultOption();
            this.setState({ value: option });
        }
    }

    getDefaultOption() {
        const options = this.props.options;
        return options.find(o => o.value.id === this.props.defaultValue.id);
    }

    handleChange = (selectedOption) => {
        this.props.onChange(selectedOption.value);
        this.setState({ value: selectedOption });
    }

    render() {
        const options = this.props.options;
        const { disabled, value } = this.state;

        return (
            <div>
                {
                    <Select options={options}
                        value={value}
                        components={{ DropdownIndicator, Option, ValueContainer }}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={null}
                        menuPlacement='auto'
                        styles={customStyles}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: "#F4F4F4",
                                primary25: "#F4F4F4",
                                primary50: "#F4F4F4",
                            }
                        })}
                        isDisabled={disabled}
                        onChange={this.handleChange}
                    />
                }
            </div>
        );
    }
}

export default StatusSelect;