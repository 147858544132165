import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { apiService } from "../services";
import { publishPanelKind } from "./enums";
import { getter } from "./helper";
import PublishMainPanel from "./publishCtrls/publishMainPanel";
import PublishSetFile from "./publishCtrls/publishSetFile";
import PublishUploadCheckPanel from "./publishCtrls/publishUploadCheckPanel";

class ExportPublisher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publishSets: [],
      panelKind: publishPanelKind.main,
      publishSet: undefined,
      publishItems: [],
      exporting_items: false,
    };

    window.exportPublisher = this;
    this.getPublishHeaders();
  }

  getPublishHeaders() {
    window.getPublishSetHeaders();
  }

  setPublishSets(headers) {
    try {
      const publishSets = JSON.parse(headers).sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      this.setState({ publishSets });
    } catch (err) {
      console.log(err);
    }
  }

  handleSetPanel = (panelKind) => {
    this.setState({ panelKind });
  };

  handlePublishData = (publishItems, publishSet) => {
    this.setState({ publishItems, publishSet });
  };

  handleSetFiles = (publishItems, publishSet) => {
    this.setState({
      //panelKind: publishPanelKind.main,
      publishItems,
      publishSet,
    });

    const data = {
      guid: publishSet.rootGuid,
      publishItems: publishItems,
      publishSet: JSON.stringify(publishSet),
      projectId: this.props.project.id,
    };
    window.preparePublishDocs(data);
  };

  preparedPublishItems(data) {
    try {
      const publishItems = data ? JSON.parse(data) : [];
      this.setState({ publishItems });
    } catch (err) {
      console.log(err);
      this.setState({ publishItems: [] });
    }

    this.setState({ panelKind: publishPanelKind.main });
  }

  handleUploadCheck = (publishItems, publishSet) => {
    this.setState({
      publishItems,
      publishSet,
      panelKind: publishPanelKind.uploadCheck,
    });
  };

  handleExport = () => {
    const data = {
      guid: this.state.publishSet.rootGuid,
      projectId: this.props.project.id,
      token: getter.getTokenBearer(),
      publishItems: this.state.publishItems,
    };

    this.setState({ exporting_items: true });
    window.publishSets(data);
  };

  completeExporting() {
    this.setState({ exporting_items: false, panelKind: publishPanelKind.main });
  }

  completePublishItems(jsItems) {
    try {
      const items = JSON.parse(jsItems);
      const models = this.state.publishItems.filter((item) => item.isImport);
      models.forEach((m) => {
        const id = items[m.key];
        if (id) {
          const persistDto = {};
          persistDto.id = id;
          persistDto.disciplineMetaDataIds = { value: [m.discipline] };
          persistDto.buildingMetaDataId = { value: m.building };
          persistDto.floorMetaDataId = { value: m.floor };

          apiService.putData("documentVersion", [persistDto]).then(() => {
            apiService.patchIfcDocument(id);
          });
        }
      });
    } catch (err) {
      console.log(err);
    }

    this.completeExporting();
  }

  failPublishItems() {
    this.completeExporting();
  }

  render() {
    const {
      panelKind,
      publishSets,
      publishSet,
      publishItems,
      exporting_items,
    } = this.state;
    return (
      <>
        {panelKind === publishPanelKind.main && (
          <PublishMainPanel
            onSetPanel={this.handleSetPanel}
            publishItems={publishItems}
            publishSet={publishSet}
            onUploadCheck={this.handleUploadCheck}
            onSetPublishData={this.handlePublishData}
          />
        )}
        {panelKind === publishPanelKind.setFile && (
          <PublishSetFile
            onSetPanel={this.handleSetPanel}
            headers={publishSets}
            header={publishSet}
            checkedItems={publishItems}
            onSetFiles={this.handleSetFiles}
          />
        )}
        {panelKind === publishPanelKind.uploadCheck && (
          <PublishUploadCheckPanel
            onSetPanel={this.handleSetPanel}
            publishItems={publishItems}
            onExport={this.handleExport}
            publishSet={publishSet}
          />
        )}
        <Backdrop
          style={{
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
          open={exporting_items}
        >
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
  };
};

export default connect(mapStateToProps, null)(ExportPublisher);
