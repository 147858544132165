import { filterConstants } from '../constants';

const initialState = {
    filterData: undefined
}

export function filter(state = initialState, action) {
    switch (action.type) {
        case filterConstants.FILTER_APPLY: {
            return {
                ...state,
                filterData: action.payload
            };
        }
        case filterConstants.FILTER_RESET: {
            return initialState;
        }

        default:
            return state;
    }
}