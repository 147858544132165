import { emailConstants } from "../constants";

const initialState = {
    emails: []
}

export function email(state = initialState, action) {
    switch (action.type) {
        case emailConstants.GET_EMAIL:
            return {
                ...state,
                emails: action.payload
            };
        default:
            return state;
    }
}