import { issueLogEntryType } from "../enums/issueLogEntryType";
import Moment from "moment";
import { issueVisibility, workPhaseType } from "../enums";

export const getter = {
  getTokenBearer,
  getHistoryContent,
  getRoleActions,
  getWorkPhase,
  getRoleId,
  isIssueVisible,
  isActionAllowed,
};

function getTokenBearer() {
  const token = JSON.parse(localStorage.getItem("projectToken"));
  return token.bearer;
}

function getHistoryContent(logEntry, statuses, priorities, types) {
  let content = undefined;
  switch (logEntry.entryType) {
    case issueLogEntryType.IssueCreated:
      content = "Issue was created";
      break;
    case issueLogEntryType.TitleUpdated:
      content = `changed title from "${logEntry.oldValue}" to "${logEntry.newValue}"`;
      break;
    case issueLogEntryType.DescriptionUpdated:
      content = "changed description";
      break;
    case issueLogEntryType.TypeUpdated:
      content = `changed type from ${getDescriptorName(
        types,
        logEntry.oldId
      )} to ${getDescriptorName(types, logEntry.newId)}`;
      break;
    case issueLogEntryType.PriorityUpdated:
      content = `changed priority from ${getDescriptorName(
        priorities,
        logEntry.oldId
      )} to ${getDescriptorName(priorities, logEntry.newId)}`;
      break;
    case issueLogEntryType.DueDateUpdated:
    case issueLogEntryType.DueDateRemoved:
      if (logEntry.newValue === undefined || logEntry.newValue === "") {
        content = "removed Due Date";
      } else if (logEntry.oldValue === undefined || logEntry.oldValue === "") {
        content = `added ${Moment(logEntry.newValue).format(
          "DD.MM.YY"
        )} as Due Date`;
      } else {
        content = `changed Due Date from ${Moment(logEntry.oldValue).format(
          "DD.MM.YY"
        )} to ${Moment(logEntry.newValue).format("DD.MM.YY")}`;
      }
      break;
    case issueLogEntryType.AssignedToUpdated:
    case issueLogEntryType.AssignedToRemoved:
      {
        const counts = getObjectListCount(logEntry.oldValue, logEntry.newValue);
        if (counts.removedCount === 0) {
          content = `added ${counts.addedCount} assigned users`;
        } else if (counts.addedCount === 0) {
          content = `removed ${counts.removedCount} assigned users`;
        } else {
          content = `added ${counts.addedCount} and removed ${counts.removedCount} assigned users`;
        }
      }
      break;
    case issueLogEntryType.DocumentFirstLinked:
    case issueLogEntryType.DocumentLinkVersionUpdated:
      break;
    case issueLogEntryType.StatusUpdate:
      content = `changed status from ${getDescriptorName(
        statuses,
        logEntry.oldId
      )} to ${getDescriptorName(statuses, logEntry.newId)}`;
      break;
    case issueLogEntryType.ReviewerUpdated:
    case issueLogEntryType.ReviewerRemoved:
      break;
    case issueLogEntryType.DisciplineMetaDataUpdated:
    case issueLogEntryType.DisciplineMetaDataRemoved:
      {
        const counts = getObjectListCount(logEntry.oldValue, logEntry.newValue);
        if (counts.removedCount === 0) {
          content = `added ${counts.addedCount} disciplines`;
        } else if (counts.addedCount === 0) {
          content = `removed ${counts.removedCount} disciplines`;
        } else {
          content = `added ${counts.addedCount} and removed ${counts.removedCount} disciplines`;
        }
      }
      break;
    case issueLogEntryType.BuildingMetaDataUpdated:
    case issueLogEntryType.BuildingMetaDataRemoved:
      {
        const counts = getObjectListCount(logEntry.oldValue, logEntry.newValue);
        if (counts.removedCount === 0) {
          content = `added ${counts.addedCount} buildings`;
        } else if (counts.addedCount === 0) {
          content = `removed ${counts.removedCount} buildings`;
        } else {
          content = `added ${counts.addedCount} and removed ${counts.removedCount} buildings`;
        }
      }
      break;
    case issueLogEntryType.FloorMetaDataUpdated:
    case issueLogEntryType.FloorMetaDataRemoved:
      {
        const counts = getObjectListCount(logEntry.oldValue, logEntry.newValue);
        if (counts.removedCount === 0) {
          content = `added ${counts.addedCount} floors`;
        } else if (counts.addedCount === 0) {
          content = `removed ${counts.removedCount} floors`;
        } else {
          content = `added ${counts.addedCount} and removed ${counts.removedCount} floors`;
        }
      }
      break;
    case issueLogEntryType.CustomDisciplineMetaDataUpdated:
    case issueLogEntryType.CustomDisciplineMetaDataRemoved:
      {
        const counts = getObjectListCount(logEntry.oldValue, logEntry.newValue);
        if (counts.removedCount === 0) {
          content = `added ${counts.addedCount} user choosen disciplines`;
        } else if (counts.addedCount === 0) {
          content = `removed ${counts.removedCount} user choosen disciplines`;
        } else {
          content = `added ${counts.addedCount} and removed ${counts.removedCount} user choosen disciplines`;
        }
      }
      break;
    case issueLogEntryType.CustomBuildingMetaDataUpdated:
    case issueLogEntryType.CustomBuildingMetaDataRemoved:
      {
        const counts = getObjectListCount(logEntry.oldValue, logEntry.newValue);
        if (counts.removedCount === 0) {
          content = `added ${counts.addedCount} user choosen buildings`;
        } else if (counts.addedCount === 0) {
          content = `removed ${counts.removedCount} user choosen buildings`;
        } else {
          content = `added ${counts.addedCount} and removed ${counts.removedCount} user choosen buildings`;
        }
      }
      break;
    case issueLogEntryType.CustomFloorMetaDataUpdated:
    case issueLogEntryType.CustomFloorMetaDataRemoved:
      {
        const counts = getObjectListCount(logEntry.oldValue, logEntry.newValue);
        if (counts.removedCount === 0) {
          content = `added ${counts.addedCount} user choosen floors`;
        } else if (counts.addedCount === 0) {
          content = `removed ${counts.removedCount} user choosen floors`;
        } else {
          content = `added ${counts.addedCount} and removed ${counts.removedCount} user choosen floors`;
        }
      }
      break;
    case issueLogEntryType.LinkedComponentsUpdated:
    case issueLogEntryType.LinkedComponentsRemoved:
      {
        const counts = getObjectListCount(logEntry.oldValue, logEntry.newValue);
        if (counts.removedCount === 0) {
          content = `added ${counts.addedCount} components`;
        } else if (counts.addedCount === 0) {
          content = `removed ${counts.removedCount} components`;
        } else {
          content = `added ${counts.addedCount} and removed ${counts.removedCount} components`;
        }
      }
      break;
    default:
      break;
  }

  return content;

  function getDescriptorName(items, id) {
    return items.find((item) => item.id === id)?.name;
  }
}

function getObjectListCount(oldValue, newValue) {
  let result = {};
  result.removedCount = oldValue.filter(
    (i) => newValue.includes(i) === false
  ).length;
  result.addedCount = newValue.filter(
    (i) => oldValue.includes(i) === false
  ).length;
  return result;
}

function getRoleActions(project, user) {
  if (user.id === undefined) {
    return null;
  }
  return project.actions;
}

function getWorkPhase(work) {
  const workPhase = Object.values(workPhaseType).find((w) => w.value === work);
  return workPhase.label;
}

function getRoleId(project, userId) {
  const role = project.collaboratorRoles.find(
    (r) => r.collaboratorId === userId
  );
  return role ? role.roleDefinitionId : null;
}

function isIssueVisible(issue, userId, roleId) {
  return (
    issue.creatAuthorId === userId ||
    issue.reviewerId === userId ||
    (issue.assignedCollaboratorIds &&
      issue.assignedCollaboratorIds.includes(userId)) ||
    issue.visibility === issueVisibility.PUBLIC.value ||
    (issue.visibility === issueVisibility.RESTRICTED.value &&
      issue.allowedCollaboratorGroups.includes(roleId))
  );
}

function isActionAllowed(roleActions, action) {
  const actionMap = roleActions.find((a) => a.action === action);
  if (!actionMap) return false;
  return actionMap.isAllowed;
}
