import React, { useCallback, useEffect, useState } from "react";
import { publishPanelKind } from "../enums";
import { apiService } from "../../services";
import { connect } from "react-redux";
import { getGeneralSvg } from "../controls/svg";
import en from "../../assets/en.json";
import de from "../../assets/de.json";
import "../../css/exportPublisher.css";

const NamingSchemeResults = ({ namingSchemeResults, fileName }) => {
  const [firstItem, setFirstItem] = useState();
  const [remainItems, setRemainItems] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [lang, setLang] = useState(en);

  useEffect(() => {
    const lang = window.isGerman ? de : en;
    setLang(lang);
  }, []);

  useEffect(() => {
    setFirstItem(namingSchemeResults[0]);
    setRemainItems(namingSchemeResults.slice(1));
  }, [namingSchemeResults]);

  const getErrorName = useCallback(
    (namingSchemeResult) => {
      if (!namingSchemeResult) return;

      const { errorPosition, errorLength } = namingSchemeResult;
      return (
        <>
          <span className="error-item">{fileName.slice(0, errorPosition)}</span>
          <span className="error-item" style={{ color: "#e9e3e3" }}>
            {fileName.slice(errorPosition, errorPosition + errorLength)}
          </span>
          <span className="error-item">
            {fileName.slice(errorPosition + errorLength, fileName.length)}
          </span>
        </>
      );
    },
    [fileName]
  );

  const getNamingSchemeResultMessage = useCallback(
    (namingSchemeResult) => {
      if (namingSchemeResult.success) {
        const content = lang["NameValidSuccess"]
          .replace("{{versionNumber}}", namingSchemeResult.predictedVersion)
          .replace("{{fileName}}", namingSchemeResult.cleanFileName);
        return namingSchemeResult.namingSchemeName
          ? `${lang[namingSchemeResult.namingSchemeName]}: ${content}`
          : content;
      }

      if (namingSchemeResult.errorType === "NamingSchemaValidationFailed") {
        return lang["NamingSchemaValidationFailed"]
          .replace("{{group}}", namingSchemeResult.failedGroupName)
          .replace(
            "{{namingSchema}}",
            lang[namingSchemeResult.namingSchemeName]
          );
      }

      return namingSchemeResult.namingSchemeName && namingSchemeResult.errorType
        ? `${lang[namingSchemeResult.namingSchemeName]}: ${
            lang[namingSchemeResult.errorType]
          }}`
        : lang["NoNamingScheme"];
    },
    [lang]
  );

  const renderRemainItems = () => {
    remainItems.map((item) => {
      return (
        <>
          <div className="horizental_align">
            <div style={{ marginRight: "8px" }}>{getGeneralSvg("leaf")}</div>
            {getErrorName(item)}
          </div>
          <label
            style={{
              color: item.success ? "#434b59" : "#e93e3e",
              fontWeight: "600",
              fontFamily: "Open Sans",
              fontSize: "10px",
              lineHeight: "14px",
            }}
          >
            {getNamingSchemeResultMessage(item)}
          </label>
        </>
      );
    });
  };

  return (
    <>
      <div className="horizental_align" onClick={() => setIsExpand(!isExpand)}>
        <div>{isExpand ? getGeneralSvg("expand") : getGeneralSvg("fold")}</div>
        <div style={{ margin: "0 8px" }}>{getGeneralSvg("leaf")}</div>
        {getErrorName(firstItem)}
      </div>
      {isExpand && (
        <div style={{ margin: "0 0 0 15px" }}>
          <label
            style={{
              color: firstItem.success ? "#434b59" : "#e93e3e",
              fontWeight: "600",
              fontFamily: "Open Sans",
              fontSize: "10px",
              lineHeight: "14px",
            }}
          >
            {getNamingSchemeResultMessage(firstItem)}
          </label>
          {renderRemainItems()}
        </div>
      )}
    </>
  );
};

const ValidateItem = ({ precheckResult }) => {
  const [lang, setLang] = useState(en);

  useEffect(() => {
    const lang = window.isGerman ? de : en;
    setLang(lang);
  }, []);

  const getPrecheckResultMessage = useCallback(() => {
    if (precheckResult.success) {
      const content = lang["NameValidSuccess"]
        .replace("{{versionNumber}}", precheckResult.predictedVersion)
        .replace("{{fileName}}", precheckResult.fileName);
      return precheckResult.predictedNamingScheme
        ? `${lang[precheckResult.predictedNamingScheme]}: ${content}`
        : content;
    }

    return lang["NoNamingScheme"];
  }, [precheckResult, lang]);

  const renderPrecheckResult = () => {
    return (
      <div style={{ marginLeft: "10px" }}>
        <div className="horizental_align">
          {getGeneralSvg("leaf")}
          <label
            style={{
              paddingLeft: "10px",
              fontWeight: "600",
              fontFamily: "Open Sans",
              fontSize: "10px",
              lineHeight: "14px",
              color: `${precheckResult.success ? "#434b59" : "#e93e3e"}`,
              margin: "5px 0",
            }}
          >
            {precheckResult.fileName}
          </label>
        </div>
        <div
          style={{
            paddingLeft: "20px",
            fontFamily: "Open Sans",
            fontSize: "10px",
            lineHeight: "14px",
            fontWeight: "400",
            color: "#434b59",
          }}
        >
          {getPrecheckResultMessage()}
        </div>
      </div>
    );
  };

  const renderNamingSchemes = () => {
    const { namingSchemeResults, fileName } = precheckResult;
    return (
      <NamingSchemeResults
        namingSchemeResults={namingSchemeResults}
        fileName={fileName}
      />
    );
  };

  return (
    <>
      {precheckResult.success || !precheckResult.namingSchemeResults.length
        ? renderPrecheckResult()
        : renderNamingSchemes()}
    </>
  );
};

class PublishUploadCheckPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { precheckResults: [], allSuccess: true, isExpand: true };
  }

  componentDidMount() {
    this.checkNameScheme();
  }

  checkNameScheme() {
    const nameMap = this.generateNameFolderMap();
    Object.keys(nameMap).forEach((folder) => {
      const endpoint = `document/check?folderId=${folder}`;
      apiService.postData(endpoint, nameMap[folder]).then((res) => {
        const { precheckResults, allSuccess } = res.data;
        this.setState({ precheckResults, allSuccess });
      });
    });
  }

  generateNameFolderMap() {
    const persist = {};
    for (let i = 0, n = this.props.publishItems.length; i < n; i++) {
      const item = this.props.publishItems[i];
      if (item.folder) {
        const docName = `${item.docName}.${item.ext}`;
        if (persist[item.folder]) {
          persist[item.folder].push(docName);
        } else {
          persist[item.folder] = [docName];
        }
      }
    }

    return persist;
  }

  handleGoBack = () => {
    this.props.onSetPanel(publishPanelKind.main);
  };

  handleExport = () => {
    this.props.onExport();
  };

  renderCheckResult() {
    return this.state.precheckResults.map((item) => {
      return <ValidateItem precheckResult={item} />;
    });
  }

  render() {
    const lang = window.isGerman ? de : en;
    const { allSuccess, isExpand } = this.state;
    return (
      <div className="exportPublish">
        <div>
          <div className="filter_back">
            <label style={{ color: "#a6a6a6" }}>{"<"}</label>
            <button
              className="transparent_button"
              style={{
                textDecoration: "underline",
                fontFamily: "Open Sans",
                fontSize: "11px",
                lineHeight: "15px",
                fontWeight: "400",
                color: "#a6a6a6",
              }}
              onClick={this.handleGoBack}
            >
              {lang["Back"]}
            </button>
          </div>
          <label
            style={{
              fontWeight: "700",
              fontFamily: "Open Sans",
              fontSize: "13px",
              lineHeight: "18px",
              color: "#666666",
              margin: "20px 0",
            }}
          >
            Upload Check
          </label>
          <div
            style={{
              fontWeight: "600",
              fontFamily: "Open Sans",
              fontSize: "10px",
              lineHeight: "14px",
              color: "#434B59",
              margin: "8px 0",
            }}
          >
            Name & Upload Information
          </div>
        </div>
        <div
          className="publish_middle scrollbar"
          style={{ maxHeight: "calc(100% - 80px)" }}
        >
          <div className="horizental_align">
            <div>
              {isExpand ? getGeneralSvg("expand") : getGeneralSvg("fold")}
            </div>
            <div style={{ margin: "0 8px" }}>{getGeneralSvg("folder")}</div>
            <label
              style={{
                fontWeight: "700",
                fontFamily: "Open Sans",
                fontSize: "10px",
                lineHeight: "14px",
                color: "#434b59",
                margin: "5px 0",
              }}
            >
              {this.props.publishSet?.title}
            </label>
          </div>
          <div style={{ marginLeft: "8px" }}>
            {isExpand && this.renderCheckResult()}
          </div>
        </div>
        <button
          className="visoplan_Actbutton"
          style={{ width: "100%" }}
          onClick={allSuccess ? this.handleExport : this.handleGoBack}
        >
          {allSuccess ? lang["ExportFiles"] : lang["EditFiles"]}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.project.selectedProject,
  };
};

export default connect(mapStateToProps, null)(PublishUploadCheckPanel);
