import React from "react";
import VisoSelect from "../controls/viso_select";
import { Tree } from "antd";
import { publishPanelKind } from "../enums";
import en from "../../assets/en.json";
import de from "../../assets/de.json";
import "../../css/exportPublisher.css";
import "@fontsource/open-sans";

const { DirectoryTree } = Tree;

class PublishSetFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publisherSets: [],
      publishSet: undefined,
      publishItems: [],
      checkedItems: [],
      checkedPublishSets: [],
    };

    window.publishSetFile = this;
  }

  componentDidMount() {
    const publisherSets = this.getPublishSetOptions();
    const publishSet = this.props.header
      ? this.props.header
      : publisherSets[0]?.value;
    this.setState({ publisherSets, publishSet });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.publishSet !== this.state.publishSet) {
      window.getPublishSetItems(JSON.stringify(this.state.publishSet));
    }
  }

  setPublishItems(jsTree) {
    try {
      const publishItems = JSON.parse(jsTree);
      const checkedPublishSets = this.props.checkedItems;
      const checkedItems = this.props.checkedItems.map((i) => i.key);
      this.setState({ publishItems, checkedItems, checkedPublishSets });
    } catch (err) {
      console.log(err);
    }
  }

  getPublishSetOptions() {
    const publisherSets = this.props.headers.map((header) => {
      return { value: header, label: header.title };
    });

    return publisherSets;
  }

  handleGoBack = () => {
    this.props.onSetPanel(publishPanelKind.main);
  };

  changePublishSet = (publishSet) => {
    this.setState({ publishSet });
  };

  onCheck = (checkedItems, info) => {
    const checkedPublishSets = info.checkedNodes.filter(
      (n) => checkedItems.includes(n.key) && !n.children
    );
    this.setState({ checkedItems, checkedPublishSets });
  };

  onAddFiles = () => {
    this.props.onSetFiles(this.state.checkedPublishSets, this.state.publishSet);
  };

  render() {
    const lang = window.isGerman ? de : en;
    const {
      publisherSets,
      publishSet,
      publishItems,
      checkedPublishSets,
      checkedItems,
    } = this.state;

    return (
      <div className="exportPublish">
        <div>
          <div className="filter_back">
            <label style={{ color: "#A6A6A6" }}>{"<"}</label>
            <button
              className="transparent_button"
              style={{
                textDecoration: "underline",
                color: "#A6A6A6",
                fontFamily: "Open Sans",
                fontSize: "11px",
                lineHeight: "15px",
              }}
              onClick={this.handleGoBack}
            >
              {lang["Back"]}
            </button>
          </div>
          <div className="horizental_align" style={{ margin: "20px 0 5px 0" }}>
            <label
              style={{
                width: "100px",
                fontFamily: "Open Sans",
                fontWeight: "700",
                fontSize: "13px",
                lineHeight: "18px",
                color: "#666666",
              }}
            >
              Publisher
            </label>
            <div
              style={{
                color: "#B3B3B3",
                fontFamily: "Open Sans",
                fontWeight: "400",
                fontSize: "11px",
                lineHeight: "15px",
              }}
            >
              {lang["AddFileToSelection"]}
            </div>
          </div>
          <div className="horizental_align" style={{ margin: "5px 0" }}>
            <label
              style={{
                width: "120px",
                fontFamily: "Open Sans",
                fontWeight: "700",
                fontSize: "10px",
                lineHeight: "14px",
                color: "#666666",
              }}
            >
              Publisher Set
            </label>
            <div style={{ margin: "0 10px", width: "100%" }}>
              {publishSet && (
                <VisoSelect
                  options={publisherSets}
                  defaultValue={publishSet}
                  onChange={this.changePublishSet}
                />
              )}
            </div>
          </div>
        </div>
        <DirectoryTree
          className="publish_middle scrollbar"
          checkable
          selectable={false}
          virtual={false}
          treeData={publishItems}
          onCheck={this.onCheck}
          checkedKeys={checkedItems}
        />
        <button
          className="visoplan_Actbutton"
          style={{ width: "100%" }}
          onClick={this.onAddFiles}
        >
          {checkedPublishSets.length
            ? `Add ${checkedPublishSets.length} Files`
            : "Add File"}
        </button>
      </div>
    );
  }
}

export default PublishSetFile;
