import { authService, apiService } from "../../services";
import {
  userConstants,
  projConstants,
  issuesConstants,
} from "../constants";

const TOKEN = "token";

export const userActions = {
  login,
  logout,
  refresh,
  twoFactorLogin,
};

function login(email, password, remember, history) {
  return (dispatch) => {
    dispatch(request());

    authService
      .login(email, password, remember)
      .then((response) => {
        if(!response.data?.isAuthenticated && response.data?.rejectionReason === 'Second Factor required.') {
          localStorage.removeItem(TOKEN);
          dispatch(registerResult(false));
          dispatch(setTwoFactor(true))
        }else {
          localStorage.setItem(TOKEN, JSON.stringify(response.data));
          dispatch(getUser());
          dispatch(registerResult(true));
          history.push("/Projects");
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        localStorage.removeItem(TOKEN);
        dispatch(registerResult(false));
      });
  };

  function request() {
    return {
      type: userConstants.USER_LOGGING,
      payload: true,
    };
  }

  function registerResult(result) {
    return {
      type: userConstants.USER_LOGIN,
      payload: result,
    };
  }
  
  function setTwoFactor(result) {
    return {
      type: userConstants.TWO_FACTOR_CODE,
      payload: result,
    };
  }
}

function twoFactorLogin(email, password, remember, authenticator_code, history) {
  return (dispatch) => {
    dispatch(request());

    authService
      .twoFactorLogin(email, password, remember, authenticator_code)
      .then((response) => {
        if(response.data.isAuthenticated) {
          localStorage.setItem(TOKEN, JSON.stringify(response.data));
          dispatch(getUser());
          dispatch(registerResult(true));
          dispatch(setTwoFactor(false))
          history.push("/Projects");
        }
        else {
          localStorage.removeItem(TOKEN);
          dispatch(registerResult(false));
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        localStorage.removeItem(TOKEN);
        dispatch(registerResult(false));
      });
  };

  function request() {
    return {
      type: userConstants.USER_LOGGING,
      payload: true,
    };
  }

  function registerResult(result) {
    return {
      type: userConstants.USER_LOGIN,
      payload: result,
    };
  }
  function setTwoFactor(result) {
    return {
      type: userConstants.TWO_FACTOR_CODE,
      payload: result,
    };
  }
}

function logout(history) {
  return (dispatch) => {
    authService
      .logout()
      .then(() => {
        dispatch(registerResult());
        dispatch(unSelectedProject());
        dispatch(resetIssues());
        localStorage.removeItem(TOKEN);
        history.push("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function registerResult() {
    return {
      type: userConstants.USER_LOGOUT,
      payload: true,
    };
  }

  function unSelectedProject() {
    return {
      type: projConstants.PROJ_CLEAR,
    };
  }

  function resetIssues() {
    return {
      type: issuesConstants.ISSUES_CLEAR,
    };
  }
}

function refresh(history) {
  return (dispatch) => {
    dispatch(request());

    authService
      .refresh()
      .then((response) => {
        if (response.status === 200) {
          dispatch(newAuth());
        }
      })
      .catch((error) => {
        console.log(error.message);
        localStorage.removeItem(TOKEN);
        dispatch(registerResult(false));
      });
  };

  function newAuth() {
    return (dispatch) => {
      authService
        .newAuth()
        .then((response) => {
          localStorage.setItem(TOKEN, JSON.stringify(response.data));
          dispatch(getUser());
          dispatch(registerResult(true));
          history.push("/Projects");
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    };
  }

  function request() {
    return {
      type: userConstants.USER_LOGGING,
      payload: true,
    };
  }

  function registerResult(result) {
    return {
      type: userConstants.USER_REFRESH,
      payload: result,
    };
  }
}

function getUser() {
  return (dispatch) => {
    apiService.getCurrentUser().then((response) => {
      dispatch(registerResult(response.data));
    });
  };

  function registerResult(result) {
    return {
      type: userConstants.USER_CURRENT,
      payload: result,
    };
  }
}
