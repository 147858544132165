import { commentConstants } from '../constants';

const initialState = {
    comments: [],
}

export function comment(state = initialState, action) {
    switch (action.type) {
        case commentConstants.COMMENT_GET: {
            return {
                ...state,
                comments: action.payload
            };
        }
        case commentConstants.COMMENT_CLAER: {
            return initialState;
        }
        case commentConstants.COMMENT_ADD: {
            return {
                ...state,
                comments: [...state.comments, ...action.payload]
            }
        }

        default:
            return state;
    }
}