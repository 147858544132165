import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { userActions, projActions, metaDataActions } from "../redux/actions";
import { issueDescriptorActions } from "../redux/actions/issueDescriptorActions";
import { apiService } from "../services";
import img from "../assets/logout.png";
import en from "../assets/en.json";
import de from "../assets/de.json";
import "../css/projects.css";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = { seletedItem: -1 };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSelectProject = this.handleSelectProject.bind(this);
    this.handleOpenProject = this.handleOpenProject.bind(this);
  }

  componentDidMount() {
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.getProjects();
    }
  }

  getProjects() {
    let user = this.props.user;

    if (user && user.projectIds) {
      this.props.loadProjects(user.projectIds);
    } else if (user) {
      this.props.loadProjects([]);
    }
  }

  async handleLogout(e) {
    e.preventDefault();
    this.props.logout(this.props.history);
  }

  handleSelectProject(e) {
    e.preventDefault();
    this.setState({ seletedItem: e.target.parentNode.rowIndex - 1 });
  }

  handleOpenProject() {
    const projects = this.props.projects;
    if (this.state.seletedItem > -1) {
      const selectedProject = projects[this.state.seletedItem];
      if (selectedProject.trialExpired) {
        const lang = window.isGerman ? de : en;
        window.AlertAPI(lang["ExpiredProject"]);
      } else {
        this.openProject(selectedProject);
      }
    }
  }

  openProject(project) {
    apiService
      .getProjectToken(project.id)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("projectToken", JSON.stringify(res.data));
          this.props.openProject(project);
          this.props.retrieveDisciplines(project.disciplineMetaDataIds);
          this.props.retrieveBuildings(project.buildingMetaDataIds);
          this.props.retrieveFloors(project.layerMetaDataIds);
          this.props.retrieveDescriptors(project.id);
          this.props.history.push("/IssueManager");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if(err.response.status === 403 && err.response.data?.translatedMessage) {
          window.AlertAPI(err.response.data?.translatedMessage)
        }
      });
  }

  renderTableData() {
    return this.props.projects.map((project, index) => {
      const { name, creationDate } = project;
      return (
        <tr
          className={
            this.state.seletedItem === index
              ? "striped_row_selected"
              : "striped_row"
          }
          key={index}
          onClick={this.handleSelectProject}
        >
          <td>{name}</td>
          <td>{Moment(creationDate).format("DD/MM/YYYY")}</td>
        </tr>
      );
    });
  }

  render() {
    const lang = window.isGerman ? de : en;
    const projects = this.props.projects;

    return (
      <div className="project_container">
        <div className="project_header">
          <div>
            <h2>{lang["Projects"]}</h2>
          </div>
          <div className="image_button">
            <img src={img} alt="" />
            <button className="transparent_button" onClick={this.handleLogout}>
              {lang["Logout"]}
            </button>
          </div>
        </div>
        <div className="project_title">
          <label>{lang["ChooseProject"]}</label>
        </div>
        <div className="project_table scrollbar">
          <table>
            <thead>
              <tr className="no_border">
                <th>{lang["Project"]}</th>
                <th>{lang["Created"]}</th>
              </tr>
            </thead>
            <tbody>{projects && this.renderTableData()}</tbody>
          </table>
        </div>
        <div className="project_open">
          <button className="visoplan_button" onClick={this.handleOpenProject}>
            {lang["OpenProject"]}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.user.loggedIn,
    user: state.user.currentUser,
    projects: state.project.projects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadProjects: (ids) => {
      dispatch(projActions.getProjects(ids));
    },
    openProject: (project) => {
      dispatch(projActions.openProject(project));
    },
    logout: (history) => {
      dispatch(userActions.logout(history));
    },
    retrieveDisciplines: (ids) => {
      dispatch(metaDataActions.getDisciplines(ids));
    },
    retrieveBuildings: (ids) => {
      dispatch(metaDataActions.getBuildings(ids));
    },
    retrieveFloors: (ids) => {
      dispatch(metaDataActions.getFloors(ids));
    },
    retrieveDescriptors: (projId) => {
      dispatch(issueDescriptorActions.getIssueDescriptors(projId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
