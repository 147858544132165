import { workPhaseKind, workPhaseType } from '../enums';
import en from '../../assets/en.json';
import de from '../../assets/de.json';

const ALL = 'All';

export const converters = {
    getOptionsFromEnum,
    getOptionsFromObject,
    getLabelByValue,
    getOptionsFromMetaData,
    getOptionsFromTag,
    getOptionsFromWorkPhaseKind,
    getOptionsFromWorkphase,
    getOptionsFromStatus
}

function getOptionsFromEnum(enumObject, addAll = false) {
    const lang = window.isGerman ? de : en;
    let options = addAll ? [{ value: ALL, label: lang[ALL] }] : [];
    return [...options, ...getData(enumObject)];

    function getData(enumObject) {
        return Object.keys(enumObject).map(k => {
            const data = enumObject[k];
            const { value, label } = data;
            const name = lang[label];

            return { value: value, label: name ? name : label };
        });
    }
}

function getOptionsFromObject(object, addAll = false) {
    const lang = window.isGerman ? de : en;
    let options = addAll ? [{ value: ALL, label: lang[ALL] }] : [];
    return [...options, ...getData()];

    function getData() {
        if (!object)
            return [];

        return Object.keys(object).map(key => {
            const data = object[key];
            return { value: data, label: data };
        });
    }
}

function getLabelByValue(enumObject, value) {
    const lang = window.isGerman ? de : en;
    const prospect = Object.values(enumObject).find(v => v.value === value);
    return prospect ? lang[prospect.label] : '';
}

function getOptionsFromMetaData(metaData, addAll = false) {
    const lang = window.isGerman ? de : en;
    let options = addAll ? [{ value: ALL, label: lang[ALL] }] : [];
    return [...options, ...getData()];

    function getData() {
        return metaData.map(m => {
            return { value: m.id, label: m.abbreviation };
        });
    }
}

function getOptionsFromTag(tags, addAll = false) {
    const lang = window.isGerman ? de : en;
    let options = addAll ? [{ value: ALL, label: lang[ALL] }] : [];
    return [...options, ...getData()];

    function getData() {
        if (tags) {
            return tags.map(t => {
                return { value: t.id, label: t.name }
            });
        }

        return [];
    }
}

function getOptionsFromStatus(statuses, addAll = false) {
    const lang = window.isGerman ? de : en;
    let options = addAll ? [{ value: ALL, label: lang[ALL] }] : [];
    return [...options, ...getData()];

    function getData() {
        if (statuses) {
            return statuses.map(t => {
                return { value: t.id, label: t.name }
            });
        }

        return [];
    }
}

function getOptionsFromWorkphase(workPhases, addAll = false) {
    const lang = window.isGerman ? de : en;
    let options = addAll ? [{ value: ALL, label: lang[ALL] }] : [];
    return [...options, ...getData()];

    function getData() {
        if (workPhases) {
            return workPhases.map(wp => {
                return { value: wp.id, label: wp.name };
            });
        }

        return [];
    }
}

function getOptionsFromWorkPhaseKind(kind, addAll = false) {
    const lang = window.isGerman ? de : en;
    let options = addAll ? [{ value: ALL, label: lang[ALL] }] : [];

    return [...options, ...getData()];

    function getData() {
        const types = Object.keys(workPhaseType).map(k => { return workPhaseType[k] });
        let data = [];
        switch (kind) {
            case workPhaseKind.Hoai:
                data = types.filter(t => t.value > 20);
                break;
            case workPhaseKind.Riba:
                data = types.filter(t => t.value > 12 && t.value < 21);
                break;
            case workPhaseKind.Sia:
                data = types.filter(t => t.value < 13);
                break;
            default:
                break;
        }

        return data;
    }
}