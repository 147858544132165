import React from 'react';
import { connect } from 'react-redux';
import { pageState } from './enums';
import ModelList from './importModel';
import ExportModel from './exportModel';
import { syncActions } from '../redux/actions';
import en from '../assets/en.json';
import de from '../assets/de.json';
import '../css/model.css';

class ModelPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentState: pageState.IMPORTMODEL };
        this.handleChangePage = this.changePage.bind(this);
        this.handleGotoIssuePage = this.handleGotoIssuePage.bind(this);
    }

    changePage(e, page) {
        e.preventDefault();
        this.setState({ currentState: page });
    }

    handleGotoIssuePage() {
        this.props.onSetPage(pageState.ISSUELIST);
    }

    render() {
        const lang = window.isGerman ? de : en;
        const { currentState } = this.state;
        const isImportPage = currentState === pageState.IMPORTMODEL;

        return (
            <div className="model_container">
                <div className="model_title">
                    <h2>{lang['Models']}</h2>
                    <label>{this.props.project.name}</label>
                </div>
                <div className="model_kind">
                    <div className={`page_tab ${isImportPage ? "select" : "unselect"}`} onClick={(e) => this.handleChangePage(e, pageState.IMPORTMODEL)}>
                        <label style={isImportPage ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>{lang['ImportIFC']}</label>
                    </div>
                    {/* <div className={`page_tab ${isImportPage ? "unselect" : "select"}`} onClick={(e) => this.handleChangePage(e, pageState.EXPORTMODEL)}>
                        <label style={isImportPage ? { fontWeight: 'normal' } : { fontWeight: 'bold' }}>{lang['ExportIFC']}</label>
                    </div> */}
                </div>
                <div className="model_seperate" />
                <div className="model_content">
                    {currentState === pageState.IMPORTMODEL && <ModelList onGotoIssuePage={this.handleGotoIssuePage} />}
                    {currentState === pageState.EXPORTMODEL && <ExportModel />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        project: state.project.selectedProject
    };
};

const mapDispatchToProps = dispatch => {
    return {
        syncProject: (projId) => { dispatch(syncActions.syncProject(projId)) }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelPage);