import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareDown, faCaretSquareUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import '../../css/viso_collapse.css';
import en from '../../assets/en.json';
import de from '../../assets/de.json';

library.add(faCaretSquareDown, faCaretSquareUp);

class MetaDataCollapse extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isCollapsed: false };
    }

    componentDidMount() {
        this.setState({ isCollapsed: this.props.defaultState });
    }

    handleClick = () => {
        const isCollapsed = this.state.isCollapsed;
        this.setState({ isCollapsed: !isCollapsed });
    }

    renderMetadatas(data) {
        return data.map(d => {
            return <div>{d}</div>
        })
    }

    render() {
        const { disciplines, buildings, floors } = this.props;
        const isCollapsed = this.state.isCollapsed;
        const lang = window.isGerman ? de : en;

        return (
            <>
                <div className='metaCollapse-header' onClick={this.handleClick}>
                    <div className='metaCollapse'>
                        <div style={{ fontWeight: 'bold' }}>{lang['Discipline']}</div>
                        <div style={{ fontWeight: 'bold' }}>{lang['Building']}</div>
                        <div style={{ fontWeight: 'bold' }}>{lang['Floor']}</div>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={isCollapsed ? 'caret-square-down' : 'caret-square-up'} color={'#666666'} />
                    </div>
                </div>
                <div className={`collapse_content ${isCollapsed ? "collapsed" : "expanded"}`} aria-expanded={isCollapsed}>
                    <div className='metaCollapse'>
                        <div>
                            {disciplines && this.renderMetadatas(disciplines)}
                        </div>
                        <div>
                            {buildings && this.renderMetadatas(buildings)}
                        </div>
                        <div>
                            {floors && this.renderMetadatas(floors)}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default MetaDataCollapse;