import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/login";
import Projects from "./components/projects";
import IssueManager from "./components/issueManager";
import { version } from "./components/helper";
import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { version: "" };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ version: version.getVersionMark() });
    }, 3000);
  }

  render() {
    const { version } = this.state;

    return (
      <>
        <Router>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/Projects" exact component={Projects} />
            <Route path="/IssueManager" exact component={IssueManager} />
          </Switch>
        </Router>
        {version && <div className="version-number">{version}</div>}
      </>
    );
  }
}

export default App;
