import { apiService } from "../../services";
import { detailConstants, editConstants, issuesConstants, viewpointConstants } from "../constants";

export const editIssueActions = {
    createIssue,
    updateIssue,
    resetPageState
}

function createIssue(issue, viewpoints) {
    return dispatch => {
        apiService.postData('issue', issue).then(res => {
            const newIssue = res.data[0];
            if (viewpoints.length > 0) {
                const newVps = viewpoints.map(v => {
                    v.issueId = { value: newIssue.id };
                    return v;
                });
                apiService.postData('viewpoint', newVps).then(vRes => {
                    dispatch(insertViewpoints(vRes.data));
                    dispatch(insertNewIssue(res.data));
                    dispatch(selectedIssue(res.data));
                    dispatch(editResult(editConstants.EDIT_SUCCESS));
                }).catch(vErr => {
                    console.log(vErr.message);
                    dispatch(editResult(editConstants.EDIT_FAIL));
                })
            } else {
                dispatch(insertNewIssue(res.data));
                dispatch(selectedIssue(res.data));
                dispatch(editResult(editConstants.EDIT_SUCCESS));
            }
            //userFeedback(true, true);
        }).catch(err => {
            console.log(err.message);
            dispatch(editResult(editConstants.EDIT_FAIL));
            //userFeedback(true, false);
        })
    };

    function insertNewIssue(data) {
        return {
            type: issuesConstants.ISSUES_INSERT,
            payload: data
        };
    }
}

function updateIssue(issue, deletedIds, newViewpoints) {
    return dispatch => {
        if (deletedIds.length > 0) {
            apiService.deleteData('viewpoint', deletedIds).then(res => {
                if (res.data === true) {
                    dispatch(deleteViewpoints(deletedIds));
                }
            }).catch(dErr => {
                console.log('Fail delete viewpoint.', dErr.message);
            })
        }

        apiService.putData('issue', issue).then(res => {
            if (newViewpoints.length > 0) {
                apiService.postData('viewpoint', newViewpoints).then(vRes => {
                    dispatch(insertViewpoints(vRes.data));
                    dispatch(updateIssueStatus(res.data));
                    dispatch(selectedIssue(res.data));
                    dispatch(editResult(editConstants.EDIT_SUCCESS));
                }).catch(vErr => {
                    console.log(vErr.message);
                    dispatch(editResult(editConstants.EDIT_FAIL));
                })
            } else {
                dispatch(updateIssueStatus(res.data));
                dispatch(selectedIssue(res.data));
                dispatch(editResult(editConstants.EDIT_SUCCESS));
            }
            //userFeedback(false, true);
        }).catch(err => {
            console.log(err.message);
            dispatch(editResult(editConstants.EDIT_FAIL));
            //userFeedback(false, false);
        })
    }

    function updateIssueStatus(data) {
        return {
            type: issuesConstants.ISSUES_UPDATE,
            payload: data[0]
        }
    }

    function deleteViewpoints(data) {
        return {
            type: viewpointConstants.VP_DELETE,
            payload: data
        }
    }
}

function resetPageState() {
    return dispatch => {
        dispatch(editResult(editConstants.EDIT_RESET));
    }
}

function insertViewpoints(viewpoints) {
    return {
        type: viewpointConstants.VP_GETALL,
        payload: viewpoints
    }
}

function selectedIssue(issue) {
    return {
        type: detailConstants.DETAIL_SELECT,
        payload: issue[0]
    }
}

function editResult(result) {
    return {
        type: result
    }
}
