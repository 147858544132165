export const issueLogEntryType = {
    IssueCreated: 1,
    TitleUpdated: 2,
    DescriptionUpdated: 3,
    TypeUpdated: 4,
    PriorityUpdated: 5,
    DueDateUpdated: 6,
    DueDateRemoved: 7,
    AssignedToUpdated: 8,
    AssignedToRemoved: 9,
    LabelAdded: 10,
    LabelRemoved: 11,
    StageAdded: 12,
    StageUpdated: 13,
    StageRemoved: 14,
    DocumentFirstLinked: 15,
    DocumentFirstLinkRemoved: 16,
    DocumentLinkVersionUpdated: 17,
    StatusUpdate: 18,
    ReviewerUpdated: 19,
    ReviewerRemoved: 20,
    DisciplineMetaDataUpdated: 21,
    DisciplineMetaDataRemoved: 22,
    BuildingMetaDataUpdated: 23,
    BuildingMetaDataRemoved: 24,
    FloorMetaDataUpdated: 25,
    FloorMetaDataRemoved: 26,
    CustomDisciplineMetaDataUpdated: 27,
    CustomDisciplineMetaDataRemoved: 28,
    CustomBuildingMetaDataUpdated: 29,
    CustomBuildingMetaDataRemoved: 30,
    CustomFloorMetaDataUpdated: 31,
    CustomFloorMetaDataRemoved: 32,
    ModelsUpdated: 33,
    ModelsRemoved: 34,
    LinkedComponentsUpdated: 35,
    LinkedComponentsRemoved: 36,
    TagsUpdated: 37,
    TagsRemoved: 38,
    ViewpointAdded: 39,
    ViewpointRemoved: 40,
    StartingDateUpdated: 41,
    StartingDateRemoved: 42,
    WorkPhaseUpdated: 43,
    WorkPhaseRemoved: 44,
    VisibilityUpdated: 45,
    AllowedRolesAdded: 46,
    LinkedEmailUpdated: 47,
    LinkedEmailRemoved: 48,
    ParentUpdated: 49,
    ParentRemoved: 50,
    StatusIdUpdated: 51,
    TypeIdUpdated: 52,
    PriorityIdUpdated: 53,
}
