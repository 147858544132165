export const roleAction = {
  ActivityLog: 0,
  ActivityLog_Export: 1,
  Project_Management: 100,
  Project_Copy: 101,
  Project_CollaboratorManagement: 102,
  Project_EditMembers: 103,
  Project_GroupManagement: 104,
  Project_CreateGroups: 105,
  Project_DeleteGroups: 106,
  Project_EditGroups: 107,
  Project_Edit_2Fa: 108,
  Project_RoleManagement: 109,
  Project_Create_Roles: 110,
  Project_Edit_Roles: 111,
  Project_Delete_Roles: 112,
  Issues: 200,
  Dashboard: 201,
  IssueTimeLine: 202,
  IssueManagement_Creation: 203,
  IssueManagement_Editing: 204,
  IssueManagement_StatusEditing: 205,
  IssueManagement_CreatingComments: 206,
  IssueManagement_BcfUpload: 207,
  IssueManagement_Export: 208,
  IssueManagement_Assigned_Editing: 209,
  IssueManagement_Assigned_StatusEditing: 210,
  IssueManagement_Creator_Editing: 211,
  IssueManagement_Creator_StatusEditing: 212,
  IssueManagement_Reviewer_Editing: 213,
  IssueManagement_Reviewer_StatusEditing: 214,
  IssueManagement_StatusEditing_SetClosed: 215,
  IssueManagement_StatusEditing_UnsetClosed: 216,
  Documents: 300,
  Document_Upload: 301,
  Document_Download: 302,
  Document_Editing: 303,
  Document_Sharing: 304,
  Document_CommentEditing: 305,
  Document_Archiving: 306,
  Document_GetArchived: 307,
  Document_RestoreArchived: 308,
  Document_Import: 310,
  Document_IfcImport: 311,
  Document_FloorPlanImport: 312,
  Document_StatusEditing: 313,
  Folder_Edit: 400,
  Folder_EditAccessList: 401,
  Folder_Create: 402,
  Folder_Delete: 403,
  Tag_Management: 500,
  Tag_Create: 501,
  Tag_Update: 502,
  Tag_Delete: 503,
  Label_Special: 550,
  Label_Special_Create: 551,
  Label_Special_Update: 552,
  Label_Special_Delete: 553,
  NamingScheme_Management: 800,
  NamingScheme_Create: 801,
  NamingScheme_Update: 802,
  NamingScheme_Delete: 803,
  DocumentList: 900,
  DocumentList_Create: 901,
  DocumentList_Edit: 902,
  DocumentList_Delete: 903,
  DocumentFilter: 950,
  DocumentFilter_Create: 951,
  DocumentFilter_Edit: 952,
  DocumentFilter_Delete: 953,
  DocStatus_ChangeToPublished: 1103,
  DocStatus_ChangeFromPublished: 1104,
  DocStamp_Edit: 1150,
  SmartView_DeleteFromDifferentCreator: 1300,
  Inbox_Edit: 1400,
  _3DModel: 1500,
  _3DModel_Properties: 1501,
  _3DModel_Edit_Properties: 1502,
  _3DModel_3D_View: 1503,
  _3DModel_SmartView: 1504,
  _2DModel: 1600,
  Email_View: 1700,
  Email_Upload: 1701,
  BCF: 2000,
  BCF_Project_update: 2001,
  BCF_Project_createTopic: 2002,
  BCF_Project_createDocument: 2003,
  BCF_Topic_update: 2004,
  BCF_Topic_updateRelatedTopics: 2005,
  BCF_Topic_updateDocumentReferences: 2006,
  BCF_Topic_updateFiles: 2007,
  BCF_Topic_createComment: 2008,
  BCF_Topic_createViewpoint: 2009,
  BCF_Topic_delete: 2010,
  BCF_Viewpoint_delete: 2011,
  BCF_Comment_update: 2012,
  BCF_Comment_delete: 2013,
};
