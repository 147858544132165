import { issueDescriptorConstants } from "../constants";

const initialState = {
    priorities: [],
    statuses: [],
    types: []
};

export function issueDescriptor(state = initialState, action) {
    switch (action.type) {
        case issueDescriptorConstants.PRIORITY: {
            return {
                ...state,
                priorities: action.payload
            }
        }
        case issueDescriptorConstants.STATUS: {
            return {
                ...state,
                statuses: action.payload
            }
        }
        case issueDescriptorConstants.TYPE: {
            return {
                ...state,
                types: action.payload
            }
        }
        default:
            return state;
    }
}