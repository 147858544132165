import { viewpointConstants } from "../constants"

const initialState = {
    viewpoints: [],
    resources: [],
    snapshotId: '',
    snapshotFlag: false
};

export function viewpoint(state = initialState, action) {
    switch (action.type) {
        case viewpointConstants.VP_GETALL: {
            let newVPs = action.payload;
            const oldVPs = state.viewpoints.filter(v => !newVPs.some(vp => vp.id === v.id));
            return {
                ...state,
                viewpoints: oldVPs.concat(newVPs)
            };
        }
        case viewpointConstants.VP_CLEAR: {
            return {
                ...state,
                viewpoints: []
            };
        }
        case viewpointConstants.VP_ADD_RESOURCE: {
            let rc = action.payload;

            return {
                ...state,
                resources: state.resources.find(r => r.id === rc.id) ? state.resources : [...state.resources, rc]
            };
        }
        case viewpointConstants.VP_SET_SNAPSHOTID: {
            return {
                ...state,
                snapshotId: action.payload,
                snapshotFlag: true
            };
        }
        case viewpointConstants.VP_CLEAR_SNAPSHOT: {
            return {
                ...state,
                snapshotId: '',
                snapshotFlag: false
            };
        }
        case viewpointConstants.VP_DELETE: {
            const deletedVpIds = action.payload;
            const updateVps = state.viewpoints.map(v => {
                if (deletedVpIds.includes(v.id))
                    v.deleted = true;
                return v;
            })

            return {
                ...state,
                viewpoints: updateVps
            }
        }

        default:
            return state;
    }
}