import { issueOwner } from '../../components/enums';
import { issuesConstants } from '../constants';

const initialState = {
    issues: [],
    owner: issueOwner.ALL.value,
}

export function issues(state = initialState, action) {
    switch (action.type) {
        case issuesConstants.ISSUES_GETALL: {
            return {
                ...state,
                issues: action.payload
            };
        }
        case issuesConstants.ISSUES_OWNER: {
            return {
                ...state,
                owner: action.payload
            };
        }
        case issuesConstants.ISSUES_CLEAR: {
            return initialState;
        }
        case issuesConstants.ISSUES_UPDATE: {
            let updateIssue = action.payload;
            const issues = state.issues;
            let index = issues.findIndex(i => i.id === updateIssue.id);
            issues.splice(index, 1);
            issues.push(updateIssue);

            return {
                ...state,
                issues: issues
            }
        }
        case issuesConstants.ISSUES_INSERT: {
            return {
                ...state,
                issues: [...state.issues, ...action.payload]
            };
        }
        case issuesConstants.ISSUES_EXTERNAL: {
            return {
                ...state,
                issues: action.payload
            };
        }
        case issuesConstants.ISSUES_RESET_OWNER: {
            return {
                ...state,
                owner: issueOwner.ALL.value
            };
        }

        default:
            return state;
    }
}