export const labelType = {
  Tags: 0,
  IssueStatus: 1,
  IssuePriority: 2,
  IssueType: 3,
  MetaDataDiscipline: 5,
  MetaDataBuilding: 6,
  MetaDataFloor: 7,
  WorkPhase: 8
};
