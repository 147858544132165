import { serviceHelper } from "../../services/helper";

export const version = {
  getVersion,
  getVersionMark,
  getUpdatedVersionUrl,
};

function getVersion() {
  return window.pluginVersion;
}

function getVersionMark() {
  let mark = getVersion();
  const url = serviceHelper.getBaseURL();
  if (url === "https://internal-api.visoplan.de/api/") {
    mark = `Internal - ${mark}`;
  } else if (url === "https://prerelease-api.visoplan.de/api/") {
    mark = `Prerelease - ${mark}`;
  }

  return mark;
}

function getUpdatedVersionUrl() {
  const url = serviceHelper.getBaseURL();
  let pipeline = "release";
  if (url === "https://internal.api-visoplan.de/api/") {
    pipeline = "internal";
  } else if (url === "https://prerelease-api.visoplan.de/api/") {
    pipeline = "prerelease";
  }

  return `https://update.visoplan.de/archicad/${pipeline}/${window.archicadVersion}/currentversion`;
}
