import { apiService } from '../../services';
import { modelConstants } from '../constants';

export const modelActions = {
    getModels,
}

/**
 * Get a list of model preview data specified by id.
 * @param {string[]} ids 
 */
function getModels(ids) {
    return dispatch => {
        apiService.getArrayOData('model', ids)
            .then(
                models => {
                    dispatch(registerModels(models));
                }
            ).catch(err => {
                console.log('Fail getting models:', err.message);
            });
    };

    function registerModels(models) {
        let previewDtos = models.map(m => {
            return m.changedFile;
        });

        return {
            type: modelConstants.MODEL_GETALL,
            payload: previewDtos
        };
    }
}