export const workPhaseType = {
    LPH11: { value: 1, label: 'LPH11' },
    LPH21: { value: 2, label: 'LPH21' },
    LPH22: { value: 3, label: 'LPH22' },
    LPH31: { value: 4, label: 'LPH31' },
    LPH32: { value: 5, label: 'LPH32' },
    LPH33: { value: 6, label: 'LPH33' },
    LPH41: { value: 7, label: 'LPH41' },
    LPH51: { value: 8, label: 'LPH51' },
    LPH52: { value: 9, label: 'LPH52' },
    LPH53: { value: 10, label: 'LPH53' },
    LPH61: { value: 11, label: 'LPH61' },
    LPH62: { value: 12, label: 'LPH62' },
    Stage0: { value: 13, label: 'Stage0' },
    Stage1: { value: 14, label: 'Stage1' },
    Stage2: { value: 15, label: 'Stage2' },
    Stage3: { value: 16, label: 'Stage3' },
    Stage4: { value: 17, label: 'Stage4' },
    Stage5: { value: 18, label: 'Stage5' },
    Stage6: { value: 19, label: 'Stage6' },
    Stage7: { value: 20, label: 'Stage7' },
    LPH1: { value: 21, label: 'LPH1' },
    LPH2: { value: 22, label: 'LPH2' },
    LPH3: { value: 23, label: 'LPH3' },
    LPH4: { value: 24, label: 'LPH4' },
    LPH5: { value: 25, label: 'LPH5' },
    LPH6: { value: 26, label: 'LPH6' },
    LPH7: { value: 27, label: 'LPH7' },
    LPH8: { value: 28, label: 'LPH8' },
    LPH9: { value: 29, label: 'LPH9' }
}