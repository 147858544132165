import React from 'react';
import { connect } from 'react-redux';
import { exportKind } from './enums';
import ExportModel from './exportModel';
import ExportOther from './exportOther';
import '../css/exportPage.css';
import ExportPublisher from './exportPublisher';

class ExportPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentState: exportKind.IFC };
        this.handleChangeFrame = this.changeFrame.bind(this);
    }

    changeFrame(e, kind) {
        e.preventDefault();
        this.setState({ currentState: kind });
    }

    render() {
        const { currentState } = this.state;

        return (
            <div className='export-container'>
                <div className='export_title'>
                    <h2>Export</h2>
                    <label>{this.props.project.name}</label>
                </div>
                <div className='export_tab'>
                    {/* <div className={`button_tab ${currentState === exportKind.Archicad ? 'select' : 'unselect'}`} onClick={(e) => this.handleChangeFrame(e, exportKind.Archicad)}>
                        <label>Archicad</label>
                    </div> */}
                    <div className={`button_tab ${currentState === exportKind.IFC ? 'select' : 'unselect'}`} onClick={(e) => this.handleChangeFrame(e, exportKind.IFC)} >
                        <label>IFC File</label>
                    </div>
                    <div className={`button_tab ${currentState === exportKind.Other ? 'select' : 'unselect'}`} onClick={(e) => this.handleChangeFrame(e, exportKind.Other)}>
                        <label>2D Export</label>
                    </div>
                    <div className={`button_tab ${currentState === exportKind.Publisher ? 'select' : 'unselect'}`} onClick={(e) => this.handleChangeFrame(e, exportKind.Publisher)}>
                        <label>Publisher</label>
                    </div>
                </div>
                <div style={{ height: '1px', width: '100%', background: '#DADADA' }} />
                <div>
                    {/* {currentState === exportKind.Archicad && <ExportArchicad />} */}
                    {currentState === exportKind.IFC && <ExportModel />}
                    {currentState === exportKind.Other && <ExportOther />}
                    {currentState === exportKind.Publisher && <ExportPublisher/>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        project: state.project.selectedProject
    }
}

export default connect(mapStateToProps, null)(ExportPage);