import API from "./api";
import { serviceHelper } from "./helper";

export const authService = {
  login: loginAsync,
  logout: logoutAsync,
  refresh: refreshAsync,
  newAuth: newAuthAsync,
  twoFactorLogin: twoFactorLoginAsync,
};

async function loginAsync(email, password, remember) {
  const data = JSON.stringify({
    Mail: email,
    Password: password,
    Remember: remember,
  });
  return await API.post(`${serviceHelper.getBaseURL()}auth-user`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

async function twoFactorLoginAsync(email, password, remember, authenticator_code) {
  const data = JSON.stringify({
    Mail: email,
    Password: password,
    Remember: remember,
    TwoFactorCode: authenticator_code,
  });
  return await API.post(`${serviceHelper.getBaseURL()}auth-user/totp`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

async function logoutAsync() {
  return await API.delete(
    `${serviceHelper.getBaseURL()}auth-user/signout`,
    serviceHelper.authHeader()
  );
}

async function refreshAsync() {
  if (serviceHelper.isAvailable()) {
    return await API.get(
      `${serviceHelper.getBaseURL()}auth-user/isvalid`,
      serviceHelper.authHeader()
    );
  } else {
    throw new Error("Your account is expired.");
  }
}

async function newAuthAsync() {
  return await API.get(
    `${serviceHelper.getBaseURL()}auth-user/newauth`,
    serviceHelper.authHeader()
  );
}
