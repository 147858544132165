import React, { createRef } from "react";
import moment from "moment";
import "../../css/datePicker.scss";
import img from "../../assets/calendar.png";
import en from "../../assets/en.json";
import de from "../../assets/de.json";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function getLocaleMonth(date) {
  return window.isGerman
    ? moment(date).locale("de").format("MMMM YYYY")
    : moment(date).locale("en").format("MMMM YYYY");
}

class VisoDatePickerSelector extends React.Component {
  state = {
    tempValue: this.props.value,
  };

  clickPreviousMonth = () => {
    this.setState({
      tempValue: moment(this.state.tempValue).subtract(1, "month").toDate(),
    });
  };

  clickNextMonth = () => {
    this.setState({
      tempValue: moment(this.state.tempValue).add(1, "month").toDate(),
    });
  };

  clickDay = (e) => {
    let oldDate = this.state.tempValue;
    let dataDate = e.currentTarget.getAttribute("data-day");
    let dataMonth = e.currentTarget.getAttribute("data-month");
    let newDate = moment(oldDate).month(dataMonth).date(dataDate).toDate();

    this.setState({
      tempValue: newDate,
    });

    if (this.props.onChangeDate) this.props.onChangeDate(oldDate, newDate);

    if (this.props.hideSelector) this.props.hideSelector();
  };

  renderDaysInMonth() {
    let { value } = this.props;
    let selectedDate = moment(value);
    let selectedTempDate = moment(this.state.tempValue);
    let startDate = moment(this.state.tempValue).date(1);

    if (startDate.days() !== 0) startDate.subtract(startDate.days(), "days");

    let rows = [];

    for (var j = 0; j < 6; j++) {
      let row = [];

      for (var i = 0; i < 7; i++) {
        let className = "datePickerSelectorTableDays";

        if (startDate.month() !== selectedTempDate.month())
          className += "NotInMonth";
        else if (
          startDate.date() === selectedDate.date() &&
          startDate.month() === selectedDate.month()
        )
          className += "Selected";

        row.push(
          <td
            onClick={this.clickDay}
            className={className}
            data-day={startDate.date()}
            data-month={startDate.month()}
          >
            {startDate.date()}
          </td>
        );

        startDate.add(1, "days");
      }

      rows.push(row);
    }

    return rows.map((row) => {
      return (
        <tr>
          {row.map((item) => {
            return item;
          })}
        </tr>
      );
    });
  }

  render = () => {
    let datePickerSelectorClassName = "datePickerSelector";
    const locale = window.isGerman;

    if (this.props.isSelectorActive) datePickerSelectorClassName += " active";

    return (
      <div
        className={datePickerSelectorClassName}
        style={{ top: `${this.props.top}px` }}
      >
        <table className="datePickerSelectorTable">
          <tr className="datePickerSelectorTableHeader">
            <td onClick={this.clickPreviousMonth}>
              <svg
                className="datePickerSelectorTableHeaderPreviousMonth svg-icon"
                viewBox="0 0 20 20"
              >
                <path
                  fill="none"
                  d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"
                ></path>
              </svg>
            </td>
            <td
              className="datePickerSelectorTableHeaderCurrentMonth"
              colSpan="5"
            >
              {getLocaleMonth(this.state.tempValue)}
            </td>
            <td onClick={this.clickNextMonth}>
              <svg
                className="datePickerSelectorTableHeaderNextMonth svg-icon"
                viewBox="0 0 20 20"
              >
                <path
                  fill="none"
                  d="m11.676108,10.049228l-4.76,-4.873c-0.303,-0.31 -0.297,-0.804 0.012,-1.105c0.309,-0.304 0.803,-0.293 1.105,0.012l5.306,5.433c0.304,0.31 0.296,0.805 -0.012,1.105l-5.432,5.307c-0.152,0.148 -0.35,0.223 -0.547,0.223c-0.203,0 -0.406,-0.08 -0.559,-0.236c-0.303,-0.309 -0.295,-0.803 0.012,-1.104l4.875,-4.762z"
                ></path>
              </svg>
            </td>
          </tr>
          <tr className="datePickerSelectorTableDaysHeader">
            <td>{locale ? "So" : "Su"}</td>
            <td>Mo</td>
            <td>{locale ? "Di" : "Tu"}</td>
            <td>{locale ? "Mi" : "We"}</td>
            <td>{locale ? "Do" : "Th"}</td>
            <td>Fr</td>
            <td>Sa</td>
          </tr>
          {this.renderDaysInMonth()}
        </table>
      </div>
    );
  };
}

class VisoDatePickerLabel extends React.Component {
  render() {
    const lang = window.isGerman ? de : en;
    const { isClearable, toggleSelector } = this.props;
    return (
      <div className="datePickerLabel">
        <div
          style={{ textOverflow: "ellipsis", overflow: "inherit" }}
          onClick={toggleSelector}
        >
          {this.props.value
            ? moment(this.props.value).format("DD/MM/YYYY")
            : lang["SelectDate"]}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isClearable && !!this.props.value && (
            <FontAwesomeIcon
              icon={faTimes}
              className="datePickerLabel_closeIcon"
              onClick={(e) => toggleSelector(e, true)}
            />
          )}
          <img src={img} alt="" onClick={toggleSelector} />
        </div>
      </div>
    );
  }
}

class VisoDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSelectorActive: false, top: 0 };
    this.elementRef = createRef();
  }

  onChangeDate = (oldDate, newDate) => {
    this.props.onChange(newDate);
  };

  onChangeShowSelector = (value) => {
    this.setState({
      isSelectorActive: value,
    });
  };

  toggleSelector = (e, del) => {
    if (del) {
      this.onChangeDate(undefined, "");
    } else {
      if (this.state.isSelectorActive) {
        this.setState({ isSelectorActive: false });
      } else {
        this.showSelector();
      }
    }
  };

  showSelector = () => {
    const labelHeight = this.elementRef.current.clientHeight;
    let ctop = labelHeight + 3;

    const parent = document.getElementsByClassName("filter_body")[0];
    if (parent) {
      const { top } = this.elementRef.current.getBoundingClientRect();
      if (top > (parent.scrollHeight - parent.scrollTop) / 2) {
        ctop = -(labelHeight + 235);
      }
    }

    this.setState({ top: ctop, isSelectorActive: true });
  };

  hideSelector = () => {
    this.onChangeShowSelector(false);
  };

  render() {
    const { isSelectorActive, top } = this.state;
    let { value, isClearable } = this.props;
    let { onChangeDate, showSelector, hideSelector, toggleSelector } = this;
    let childProps = {
      value,
      isSelectorActive,
      onChangeDate,
      showSelector,
      hideSelector,
      toggleSelector,
      isClearable,
      top,
    };

    return (
      <div className="datePicker" ref={this.elementRef}>
        <VisoDatePickerLabel {...childProps} />
        <VisoDatePickerSelector {...childProps} />
      </div>
    );
  }
}

export default VisoDatePicker;
