import { editConstants } from "../constants";
import { editResult } from "../../components/enums";

const initialState = {
    result: editResult.NONE
}

export function editReport(state = initialState, action) {
    switch (action.type) {
        case editConstants.EDIT_SUCCESS: {
            return {
                ...state,
                result: editResult.SUCCESS
            };
        }
        case editConstants.EDIT_FAIL: {
            return {
                ...state,
                result: editResult.FAIL
            };
        }
        case editConstants.EDIT_RESET: {
            return initialState;
        }

        default:
            return state;
    }
}