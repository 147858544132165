import React from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import Image from './image';
import { getGeneralSvg, getTypeSvg } from './svg';
import { viewpointActions, detailActions } from '../../redux/actions';
import en from '../../assets/en.json';
import de from '../../assets/de.json';
import 'moment/locale/de';
import '../../css/issueItem.css';
import Circle from './circle';

class IssueItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { imageId: '', elementIds: [], tags: [] };
        this.handleClick = this.handleSelectIssue.bind(this);
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
    }

    componentDidMount() {
        this.getIssueInformation();
    }

    componentDidUpdate(prevProps) {
        if (this.props.issue !== prevProps.issue) {
            this.getIssueInformation();
        }

        if (this.props.viewpoints !== prevProps.viewpoints) {
            this.getImage();
        }
    }

    getIssueInformation() {
        const vpIds = this.props.issue.viewpointIds;
        this.props.addViewpoint(vpIds);
        this.getElementCounts();
        this.getImage();
    }

    getImage() {
        const imageId=this.props.issue.thumbnailImageId||'';
        this.setState({imageId});
    }

    getElementCounts() {
        let ids = this.props.issue.linkedComponentsGlobalIds;
        ids = [...new Set(ids)];
        this.setState({ elementIds: ids });
    }

    handleSelectIssue(e) {
        e.preventDefault();
        const linkIds = this.state.elementIds;
        window.HighlightComponentIds(linkIds);
    }

    handleDoubleClick(e) {
        e.preventDefault();
        this.props.selectedIssue(this.props.issue);
        this.props.onDblClick();
    }

    render() {
        const lang = window.isGerman ? de : en;
        const issue = this.props.issue;
        const dueDate = `Due to ${issue.dueDate ? window.isGerman ? Moment(issue.dueDate).format('DD.MM.YYYY') : Moment(issue.dueDate).format('DD/MM/YYYY') : ''}`;
        const imageId = this.state.imageId;

        return (
            <div className='item_body' onClick={this.handleClick} onDoubleClick={this.handleDoubleClick}>
                <div className='item_body_content'>
                    <div className='flex_content'>
                        <div className='flex_content' style={{ padding: '5px', color: issue.issueType?.color, background: `${issue.issueType?.color}30`, height: '24px', borderRadius: '3px' }}>
                            {getTypeSvg(issue.issueType?.icon, issue.issueType?.color)}
                            <label style={{ marginLeft: '5px' }}>{issue.issueType?.name}</label>
                        </div>
                        {issue.dueDate &&
                            <div className='flex_content' style={{ padding: '5px', background: '#F88282', height: '24px', borderRadius: '3px', color: 'white' }}>
                                {dueDate}
                            </div>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems:'center' }}>
                        <Circle color={issue.issueStatus?.color}/>
                        <div className='ellipsis' style={{ padding: '3px 0', fontWeight: 'bold' }}>{`${issue.issueNumber} ${issue.title}`}</div>
                    </div>
                    <div className='ellipsis' style={{ maxHeight: '40px' }}>{issue.description}</div>
                    {imageId && <Image id={imageId} isList={true} />}
                </div>
                <div className='item_body_bottom'>
                    <div>
                        <div className='flex_content' style={{ display: issue.childrenIds?.length ? '' : 'none' }}>
                            {getGeneralSvg('subtask')}
                            <div style={{ color: '#4B6FFC', fontWeight: 'bold', marginLeft: '5px' }}>{`${issue.childrenIds?.length}${lang['SubTasks']}`}</div>
                        </div>
                    </div>
                    <div style={{ display: issue.assignedUsers?.length ? '' : 'none' }}>
                        {issue.assignedUsers.length &&
                            <div className='flex_content' style={{ color: '#4B6FFC' }}>
                                {`${lang['AssignedTo']}: ${issue.assignedUsers[0].firstName} ${issue.assignedUsers[0].lastName}`}
                                {issue.assignedUsers.length > 1 &&
                                    <span style={{ textDecoration: 'underline', marginLeft: '3px' }}>{`+${issue.assignedUsers.length - 1} more`}</span>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        viewpoints: state.viewpoint.viewpoints,
        project: state.project.selectedProject,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addViewpoint: (ids) => { dispatch(viewpointActions.getViewpoint(ids)) },
        selectedIssue: (issue) => { dispatch(detailActions.selectedIssue(issue)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueItem);